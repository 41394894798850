import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import RichTextEditor from '../common/RichTextEditor';
import { toast } from 'react-hot-toast';

// Segédfüggvény a dátum ISO string formátumához
const getISODate = (date) => {
  if (!date) return '';
  
  // Ha Timestamp objektum
  if (date.toDate) {
    return date.toDate().toISOString().split('T')[0];
  }
  
  // Ha már Date objektum vagy string
  return new Date(date).toISOString().split('T')[0];
};

export default function TimeEntryForm({ 
  projectId, 
  onSuccess, 
  onCancel,
  initialData,
  isEditing,
  entryId
}) {
  const [date, setDate] = useState(
    initialData?.date ? getISODate(initialData.date) : new Date().toISOString().split('T')[0]
  );
  const [duration, setDuration] = useState(initialData?.duration?.toString() || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [selectedTask, setSelectedTask] = useState(initialData?.taskId || '');
  const [submitting, setSubmitting] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { currentUser } = useAuth();

  // Projekt feladatainak betöltése - módosított verzió
  useEffect(() => {
    const loadTasks = async () => {
      try {
        const q = query(
          collection(db, 'tasks'),
          where('projectId', '==', projectId)
          // Eltávolítottuk a status szűrést, hogy a lezárt feladatok is megjelenjenek szerkesztésnél
        );
        const snapshot = await getDocs(q);
        const tasksData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTasks(tasksData);

        // Ha van kezdeti task, de nincs a listában (pl. már lezárt), hozzáadjuk
        if (initialData?.taskId && !tasksData.find(t => t.id === initialData.taskId)) {
          const taskDoc = await getDoc(doc(db, 'tasks', initialData.taskId));
          if (taskDoc.exists()) {
            setTasks(prev => [...prev, { id: taskDoc.id, ...taskDoc.data() }]);
          }
        }
      } catch (error) {
        console.error('Hiba a feladatok betöltésekor:', error);
      }
    };

    loadTasks();
  }, [projectId, initialData?.taskId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      // Keressük meg a kiválasztott task adatait
      const selectedTaskData = tasks.find(t => t.id === selectedTask);
      
      const timeEntryData = {
        date: new Date(date),
        duration: parseFloat(duration),
        description,
        taskId: selectedTask || null,
        taskTitle: selectedTaskData?.title || null,  // Task címének mentése
        taskStatus: selectedTaskData?.status || null  // Task státuszának mentése is hasznos lehet
      };

      if (isEditing) {
        // Szerkesztés
        const entryRef = doc(db, 'timeEntries', entryId);
        await updateDoc(entryRef, {
          ...timeEntryData,
          updatedAt: new Date()
        });
        toast.success('Időbejegyzés sikeresen módosítva!');
      } else {
        // Új bejegyzés
        await addDoc(collection(db, 'timeEntries'), {
          ...timeEntryData,
          projectId,
          userId: currentUser.uid,
          userName: currentUser.displayName || currentUser.email,
          userPhotoURL: currentUser.photoURL,
          createdAt: new Date()
        });
        toast.success('Időbejegyzés sikeresen hozzáadva!');
      }

      if (onSuccess) onSuccess();
      
      if (!isEditing) {
        // Csak új bejegyzésnél töröljük az űrlapot
        setDate(new Date().toISOString().split('T')[0]);
        setDuration('');
        setDescription('');
        setSelectedTask('');
      }
    } catch (error) {
      console.error('Hiba történt:', error);
      toast.error(isEditing ? 'Hiba a módosítás során!' : 'Hiba a mentés során!');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Dátum
          </label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Óraszám
          </label>
          <input
            type="number"
            step="0.25"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            placeholder="pl.: 2.5"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Feladat (opcionális)
        </label>
        <select
          value={selectedTask}
          onChange={(e) => setSelectedTask(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Válassz feladatot...</option>
          {tasks.map(task => (
            <option key={task.id} value={task.id}>
              {task.title} {task.status === 'completed' ? '(Lezárt)' : ''}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Leírás
        </label>
        <div className="mt-1">
          <RichTextEditor
            content={description}
            onChange={setDescription}
            placeholder="Add meg mit csináltál..."
          />
        </div>
      </div>

      <div className="flex justify-end gap-3">
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            Mégsem
          </button>
        )}
        <button
          type="submit"
          disabled={submitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {submitting ? 'Mentés...' : isEditing ? 'Módosítás' : 'Hozzáadás'}
        </button>
      </div>
    </form>
  );
} 
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Avatar from '../common/Avatar';
import { useState, useEffect } from 'react';
import NotificationDropdown from '../notifications/NotificationDropdown';
import Snowfall from './Snowfall';
import youDeskLogo from '../ui/youdesk_logo.svg';

export default function Navbar() {
  const { currentUser, signOut } = useAuth();
  const [spinnerChar, setSpinnerChar] = useState('|');
  const [showQuote, setShowQuote] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Példa idézetek (később jöhet adatbázisból)
  const quotes = [
    { author: 'Nagy Roland', quote: 'Minden bug egy új lehetőség' },
    { author: 'Kecskés Péter', quote: 'All systems are available' },
  ];

  // Spinner animáció
  useEffect(() => {
    const chars = ['|', '/', '─', '\\'];
    let index = 0;
    
    const interval = setInterval(() => {
      index = (index + 1) % chars.length;
      setSpinnerChar(chars[index]);
    }, 250);

    return () => clearInterval(interval);
  }, []);

  // Idézet megjelenítő animáció - csak asztali nézetben
  useEffect(() => {
    const isMobile = window.innerWidth < 768; // md breakpoint
    if (!currentUser || isMobile) return;

    const showNextQuote = () => {
      const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
      setCurrentQuote(randomQuote);
      setShowQuote(true);

      setTimeout(() => {
        setShowQuote(false);
        setTimeout(() => {
          setCurrentQuote(null);
        }, 500);
      }, 4000);
    };

    const interval = setInterval(showNextQuote, 8000);
    showNextQuote();

    return () => clearInterval(interval);
  }, [currentUser]);

  // Téma váltás kezelése - első betöltéskor is fusson le
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  // Első betöltéskor állítsuk be a dark mode-ot
  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  return (
    <nav className="bg-gradient-to-r from-indigo-600 to-purple-600 shadow relative">
      <div className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              {/* Logo és cím */}
              <div className="flex-shrink-0 flex items-center">
                <Link to={currentUser ? "/" : "/login"} className="flex items-center space-x-2">
                  <img
                    src={youDeskLogo}
                    alt="YouDesk"
                    className="h-8 sm:h-10"
                  />
                  <div className="flex items-center">
                    <div className="flex items-center space-x-2">
                      <span className="font-mono text-white/80 animate-pulse ml-2">
                        {spinnerChar}
                      </span>
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-500 text-white border border-green-400">
                        BETA
                      </span>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Idézet megjelenítő - csak asztali nézetben */}
              {currentUser && currentQuote && (
                <div className="hidden md:flex ml-6 items-center transition-all duration-500 ease-in-out
                  ${showQuote ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-4'}">
                  <div className="flex items-center space-x-2 bg-gray-50 dark:bg-gray-800 px-4 py-2 rounded-lg">
                    <div className="flex flex-col">
                      <p className="text-sm text-gray-600 dark:text-gray-300 italic">"{currentQuote.quote}"</p>
                      <span className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">— {currentQuote.author}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Felhasználói menü */}
            {currentUser && (
              <div className="flex items-center">
                <div className="ml-3 relative">
                  <div className="flex items-center space-x-4">
                    <NotificationDropdown />
                    <Link to="/profile" className="flex items-center space-x-3 hover:opacity-80">
                      <div className="animate-spin-slow">
                        <Avatar user={currentUser} size="sm" />
                      </div>
                      <span className="hidden md:block text-sm font-medium text-white">
                        {currentUser.displayName || currentUser.email}
                      </span>
                    </Link>
                    <button
                      onClick={signOut}
                      className="text-sm font-medium text-white/90 hover:text-white focus:outline-none"
                    >
                      <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                    </button>
                    <button
                      onClick={() => setIsDarkMode(!isDarkMode)}
                      className="text-sm font-medium text-white/90 hover:text-white focus:outline-none"
                    >
                      {isDarkMode ? (
                        <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m8-9h1M4 12H3m15.364-6.364l.707-.707M6.343 17.657l-.707.707m12.728 0l.707-.707M6.343 6.343l-.707-.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12.79A9 9 0 1111.21 3a7 7 0 109.79 9.79z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Béta környezet figyelmeztetés */}
      <div className="bg-white/10 border-b border-white/20">
        <div className="max-w-7xl mx-auto py-1 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center text-xs text-white/90">
            <svg className="h-4 w-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="hidden sm:inline">Ez egy béta környezet. Az adatok és funkciók tesztelés alatt állnak.</span>
            <span className="sm:hidden">Béta verzió</span>
          </div>
        </div>
      </div>
    </nav>
  );
} 
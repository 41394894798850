import { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, orderBy, addDoc, getDocs, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import RichTextEditor from '../common/RichTextEditor';
import Avatar from '../common/Avatar';

export default function OrganizationNotes({ organizationId }) {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const notesEndRef = useRef(null);

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Jegyzetek real-time betöltése
  useEffect(() => {
    if (!organizationId) return;

    const q = query(
      collection(db, 'organizationNotes'),
      where('organizationId', '==', organizationId),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotes(notesData);
      setLoading(false);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [organizationId]);

  // Új jegyzet hozzáadása
  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    try {
      const noteData = {
        content: newNote,
        organizationId,
        userId: currentUser.uid,
        createdAt: new Date(),
        createdBy: {
          id: currentUser.uid,
          name: currentUser.displayName || currentUser.email,
          photoURL: currentUser.photoURL
        }
      };

      await addDoc(collection(db, 'organizationNotes'), noteData);
      setNewNote('');
    } catch (error) {
      console.error('Hiba a jegyzet mentésekor:', error);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex items-center">
          <svg className="h-5 w-5 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
          </svg>
          Csevegés
        </h3>
        
        {/* Jegyzetek listája */}
        <div className="space-y-4 max-h-[500px] overflow-y-auto mb-4 p-4 bg-gray-50 rounded-lg">
          {loading ? (
            <div className="text-center text-gray-500">Betöltés...</div>
          ) : notes.length === 0 ? (
            <div className="text-center text-gray-500">Még nincsenek üzenetek</div>
          ) : (
            notes.map(note => (
              <div 
                key={note.id} 
                className={`flex items-start space-x-3 ${
                  note.userId === currentUser.uid ? 'flex-row-reverse space-x-reverse' : ''
                }`}
              >
                <Avatar 
                  user={note.createdBy}
                  size="sm"
                />
                <div 
                  className={`flex-1 rounded-lg p-3 ${
                    note.userId === currentUser.uid 
                      ? 'bg-indigo-50 ml-12' 
                      : 'bg-white mr-12'
                  }`}
                >
                  <div className="flex items-center justify-between mb-1">
                    <span className="text-sm font-medium text-gray-900">
                      {note.createdBy.name}
                    </span>
                    <span className="text-xs text-gray-500">
                      {note.createdAt.toDate().toLocaleString()}
                    </span>
                  </div>
                  <div 
                    className="prose prose-sm max-w-none"
                    dangerouslySetInnerHTML={{ __html: note.content }}
                  />
                </div>
              </div>
            ))
          )}
          <div ref={notesEndRef} />
        </div>

        {/* Új üzenet form */}
        <form onSubmit={handleAddNote} className="mt-4">
          <div className="mb-3">
            <RichTextEditor
              content={newNote}
              onChange={setNewNote}
              placeholder="Írj egy üzenetet..."
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
              Küldés
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 
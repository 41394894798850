import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, addDoc, getDoc, doc } from 'firebase/firestore';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import UserSelect from '../common/UserSelect';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TipTapLink from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import { createNotification } from '../../utils/notifications';

const truncateName = (name, maxLength = 5) => {
  if (!name) return 'Betöltés...';
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

function RichTextEditor({ content, onChange, placeholder = 'Add meg a feladat részletes leírását...' }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      TipTapLink.configure({
        openOnClick: false,
      }),
      Image,
      Underline,
    ],
    content: content,
    editable: true,
    autofocus: false,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      if (html !== content) {
        onChange(html);
      }
    },
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const addLink = () => {
    const url = window.prompt('Add meg a link URL-jét:');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  const addImage = () => {
    const url = window.prompt('Add meg a kép URL-jét:');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="border border-gray-300 rounded-md">
      <div className="border-b border-gray-300 bg-gray-50 p-2 flex flex-wrap gap-1">
        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bold') ? 'bg-gray-200' : ''}`}
            title="Félkövér"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 4h8a4 4 0 014 4 4 4 0 01-4 4H6z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h9a4 4 0 014 4 4 4 0 01-4 4H6z" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('italic') ? 'bg-gray-200' : ''}`}
            title="Dőlt"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 4h-9M14 20H5M15 4L9 20" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('underline') ? 'bg-gray-200' : ''}`}
            title="Aláhúzott"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 3v7a6 6 0 006 6 6 6 0 006-6V3M4 21h16" />
            </svg>
          </button>
        </div>

        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bulletList') ? 'bg-gray-200' : ''}`}
            title="Felsorolás"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('orderedList') ? 'bg-gray-200' : ''}`}
            title="Számozott lista"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20h14M7 12h14M7 4h14M3 20h.01M3 12h.01M3 4h.01" />
            </svg>
          </button>
        </div>

        <div className="flex space-x-1">
          <button
            onClick={addLink}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('link') ? 'bg-gray-200' : ''}`}
            title="Link beszúrása"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
          </button>
          <button
            onClick={addImage}
            className="p-1.5 rounded hover:bg-gray-200"
            title="Kép beszúrása"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>
        </div>
      </div>
      <EditorContent 
        editor={editor} 
        className="prose max-w-none p-4 min-h-[150px] focus:outline-none"
      />
    </div>
  );
}

export default function NewTask() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('new');
  const [dueDate, setDueDate] = useState('');
  const [assignedUsers, setAssignedUsers] = useState([]);
  const navigate = useNavigate();
  const { orgId, projectId } = useParams();
  const [organizationName, setOrganizationName] = useState('');
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    async function fetchNames() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      const projectDoc = await getDoc(doc(db, 'projects', projectId));
      
      if (orgDoc.exists()) setOrganizationName(orgDoc.data().name);
      if (projectDoc.exists()) setProjectName(projectDoc.data().name);
    }
    fetchNames();
  }, [orgId, projectId]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!projectId) {
      console.error('No project ID provided');
      return;
    }

    try {
      const taskRef = await addDoc(collection(db, 'tasks'), {
        title,
        description,
        status,
        projectId,
        dueDate: dueDate ? new Date(dueDate) : null,
        assignedUsers,
        createdAt: new Date()
      });

      const notificationPromises = assignedUsers.map(userId => 
        createNotification({
          userId,
          title: 'Új feladat',
          message: `Új feladat lett létrehozva: "${title}"`,
          type: 'task_created',
          link: `/organization/${orgId}/projects/${projectId}/tasks/${taskRef.id}`,
          metadata: {
            taskId: taskRef.id,
            projectId,
            orgId,
            taskUrl: `/organization/${orgId}/projects/${projectId}/tasks/${taskRef.id}`
          }
        })
      );

      await Promise.all(notificationPromises);

      navigate(`/organization/${orgId}/projects/${projectId}/tasks/${taskRef.id}`);
    } catch (error) {
      console.error('Hiba a task létrehozásakor:', error);
    }
  }

  const handleDescriptionChange = (newContent) => {
    setDescription(newContent);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <RouterLink to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </RouterLink>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><RouterLink to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</RouterLink></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li>
            <RouterLink to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <span className="hidden sm:inline">{organizationName || 'Betöltés...'}</span>
              <span className="sm:hidden">{truncateName(organizationName)}</span>
            </RouterLink>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><RouterLink to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</RouterLink></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li>
            <RouterLink to={`/organization/${orgId}/projects/${projectId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <span className="hidden sm:inline">{projectName || 'Betöltés...'}</span>
              <span className="sm:hidden">{truncateName(projectName)}</span>
            </RouterLink>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li className="text-gray-500 font-medium">Új feladat</li>
        </ol>
      </nav>

      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Új feladat létrehozása
          </h2>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Task címe *
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                📝 Leírás
              </label>
              <div className="mt-1">
                <RichTextEditor
                  content={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Státusz
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="new">Új</option>
                <option value="in_progress">Folyamatban</option>
                <option value="completed">Kész</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Határidő *
              </label>
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Felelősök
              </label>
              <div className="mt-1">
                <UserSelect
                  organizationId={orgId}
                  selectedUsers={assignedUsers}
                  onChange={setAssignedUsers}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Létrehozás
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';
import TaskList from '../task/TaskList';
import ProjectDocuments from './ProjectDocuments';
import TimeEntryForm from '../timeTracking/TimeEntryForm';
import TimeEntriesList from '../timeTracking/TimeEntriesList';
import { toast } from 'react-hot-toast';

// Projekt státuszok és színeik
const PROJECT_STATUSES = {
  active: {
    label: 'Aktív',
    class: 'bg-gradient-to-r from-green-500 to-emerald-600 text-white border-transparent',
    hoverClass: 'hover:from-green-600 hover:to-emerald-700',
    icon: (isActive) => (
      <svg className={`w-4 h-4 mr-1.5 ${isActive ? 'text-white' : 'text-gray-600'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
  },
  on_hold: {
    label: 'Függőben',
    class: 'bg-gradient-to-r from-amber-500 to-yellow-600 text-white border-transparent',
    hoverClass: 'hover:from-amber-600 hover:to-yellow-700',
    icon: (isActive) => (
      <svg className={`w-4 h-4 mr-1.5 ${isActive ? 'text-white' : 'text-gray-600'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
  },
  completed: {
    label: 'Lezárt',
    class: 'bg-gradient-to-r from-blue-500 to-cyan-600 text-white border-transparent',
    hoverClass: 'hover:from-blue-600 hover:to-cyan-700',
    icon: (isActive) => (
      <svg className={`w-4 h-4 mr-1.5 ${isActive ? 'text-white' : 'text-gray-600'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    )
  },
  cancelled: {
    label: 'Megszakítva',
    class: 'bg-gradient-to-r from-rose-500 to-red-600 text-white border-transparent',
    hoverClass: 'hover:from-rose-600 hover:to-red-700',
    icon: (isActive) => (
      <svg className={`w-4 h-4 mr-1.5 ${isActive ? 'text-white' : 'text-gray-600'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    )
  }
};

// Add this helper function
const renderHTML = (html) => {
  return { __html: html };
};

export default function ProjectDetail() {
  const [project, setProject] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const { orgId, projectId } = useParams();
  const [organizationName, setOrganizationName] = useState('');
  const [showTimeEntry, setShowTimeEntry] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toISOString().slice(0, 7)
  );
  const [timeEntriesKey, setTimeEntriesKey] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        
        if (!projectSnap.exists()) {
          setError('A projekt nem található');
          return;
        }

        const projectData = { id: projectSnap.id, ...projectSnap.data() };
        setProject(projectData);

        if (projectData.clientId) {
          const clientRef = doc(db, 'clients', projectData.clientId);
          const clientSnap = await getDoc(clientRef);
          if (clientSnap.exists()) {
            setClient(clientSnap.data());
          }
        }
      } catch (err) {
        console.error('Hiba az adatok betöltésekor:', err);
        setError('Hiba történt az adatok betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [projectId]);

  useEffect(() => {
    async function fetchOrgName() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    }
    fetchOrgName();
  }, [orgId]);

  const handleStatusChange = async (newStatus) => {
    if (updating || project.status === newStatus) return;
    
    setUpdating(true);
    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        status: newStatus,
        updatedAt: new Date()
      });
      
      setProject(prev => ({
        ...prev,
        status: newStatus,
        updatedAt: new Date()
      }));

      toast.success('Projekt státusz sikeresen módosítva!');
    } catch (err) {
      console.error('Hiba a státusz módosításakor:', err);
      toast.error('Hiba történt a státusz módosításakor');
    } finally {
      setUpdating(false);
    }
  };

  const truncateName = (name, maxLength = 5) => {
    if (!name) return 'Betöltés...';
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  // Handler a sikeres mentés után
  const handleTimeEntrySuccess = () => {
    setShowTimeEntry(false);
    setTimeEntriesKey(prev => prev + 1); // Force refresh the list
    toast.success('Időbejegyzés sikeresen mentve!');
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;
  if (!project) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-indigo-50 to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Breadcrumb - modernebb megjelenés */}
        <nav className="mb-8 bg-white/80 backdrop-blur-sm shadow-sm rounded-lg px-4 py-2 border border-white/20" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2 text-[8pt]">
            <li className="flex items-center">
              <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
                <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </Link>
            </li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <span className="hidden sm:inline">{organizationName || 'Betöltés...'}</span>
              <span className="sm:hidden">{truncateName(organizationName)}</span>
            </Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li className="text-gray-500 font-medium">
              <span className="hidden sm:inline">{project?.name || 'Projekt részletek'}</span>
              <span className="sm:hidden">{truncateName(project?.name) || 'Projekt részletek'}</span>
            </li>
          </ol>
        </nav>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Bal oldali oszlop */}
          <div className="lg:w-2/3 space-y-6">
            {/* Projekt fejléc */}
            <div className="bg-gradient-to-br from-gray-800 via-gray-900 to-black rounded-lg shadow-xl text-white">
              <div className="px-8 py-6">
                {/* Cím és státusz sor */}
                <div className="flex items-center space-x-3 mb-4">
                  <h1 className="text-2xl font-bold">{project?.name}</h1>
                  <span className={`
                    inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                    ${PROJECT_STATUSES[project.status].class}
                  `}>
                    {PROJECT_STATUSES[project.status].icon(true)}
                    {PROJECT_STATUSES[project.status].label}
                  </span>
                </div>

                {/* Leírás külön szekcióban */}
                {project?.description && (
                  <div className="border-t border-gray-700 -mx-8 px-8 py-4 mt-4">
                    <div 
                      className="prose prose-sm prose-invert max-w-none text-gray-300"
                      dangerouslySetInnerHTML={renderHTML(project.description)}
                    />
                  </div>
                )}

                {/* Metrikák */}
                <div className="flex items-center mt-4 space-x-6 text-sm text-gray-400">
                  <div className="flex items-center">
                    <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span>
                      Létrehozva: {project.createdAt?.toDate().toLocaleDateString()}
                    </span>
                  </div>
                  {project.deadline && (
                    <div className="flex items-center">
                      <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span>
                        Határidő: {project.deadline.toDate().toLocaleDateString()}
                        {project.deadline.toDate() < new Date() ? (
                          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-900 text-red-200">
                            Lejárt
                          </span>
                        ) : (
                          project.deadline.toDate() - new Date() < 7 * 24 * 60 * 60 * 1000 && (
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-900 text-yellow-200">
                              Közelgő
                            </span>
                          )
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {/* Gombok */}
              <div className="border-t border-gray-700 px-8 py-4 flex justify-end space-x-3">
                <Link
                  to={`/organization/${orgId}/projects/${projectId}/settings`}
                  className="inline-flex items-center px-4 py-2 border border-gray-600 rounded-lg text-sm font-medium text-gray-300 bg-gray-800/50 hover:bg-gray-700 transition-colors duration-200"
                >
                  <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  Beállítások
                </Link>
                {client && (
                  <Link
                    to={`/organization/${orgId}/clients/${client.id}`}
                    className="inline-flex items-center px-4 py-2 border border-gray-600 rounded-lg text-sm font-medium text-gray-300 bg-gray-800/50 hover:bg-gray-700 transition-colors duration-200"
                  >
                    <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                    {client.name}
                  </Link>
                )}
              </div>
            </div>

            {/* Metrikák konténer */}
            <div className="grid grid-cols-1 gap-6">
              {/* Státusz */}
              <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
                <h3 className="text-sm font-medium text-gray-500 mb-4 flex items-center">
                  <svg className="h-5 w-5 mr-2 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                  Státusz
                </h3>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(PROJECT_STATUSES).map(([status, info]) => {
                    const isActive = project.status === status;
                    return (
                      <button
                        key={status}
                        onClick={() => handleStatusChange(status)}
                        disabled={updating || isActive}
                        className={`
                          inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium
                          shadow-sm transition-all duration-200 ease-in-out
                          ${isActive ? info.class : 'bg-white text-gray-600 hover:bg-gray-50'}
                          ${updating ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                        `}
                      >
                        {info.icon(isActive)}
                        {info.label}
                        {isActive && (
                          <span className="ml-1.5 w-1.5 h-1.5 bg-white rounded-full animate-pulse" />
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* Határidő */}
              <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
                <h3 className="text-sm font-medium text-gray-500 mb-3">Határidő</h3>
                {project.deadline ? (
                  <div className="flex items-center">
                    <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div>
                      <span className="text-sm text-gray-900">
                        {project.deadline.toDate().toLocaleDateString()}
                      </span>
                      {project.deadline.toDate() < new Date() ? (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          Lejárt
                        </span>
                      ) : (
                        project.deadline.toDate() - new Date() < 7 * 24 * 60 * 60 * 1000 && (
                          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            Közelgő
                          </span>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center text-sm text-gray-500">
                    <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Nincs megadva
                  </div>
                )}
              </div>

              {/* Létrehozva */}
              <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
                <h3 className="text-sm font-medium text-gray-500 mb-3">Létrehozva</h3>
                <div className="flex items-center">
                  <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span className="text-sm text-gray-900">
                    {project.createdAt?.toDate().toLocaleDateString()}
                  </span>
                </div>
              </div>

              {/* Ügyfél - már gradiens háttérrel rendelkezik */}
              <div className="bg-gradient-to-br from-indigo-500 via-purple-500 to-indigo-600 rounded-lg p-6 text-white shadow-lg">
                <h3 className="text-sm font-medium text-indigo-100 mb-4 flex items-center">
                  <svg className="h-5 w-5 mr-2 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  Ügyfél
                </h3>
                {client ? (
                  <div className="space-y-4">
                    <div className="flex items-center bg-white/10 rounded-lg p-3">
                      <svg className="h-5 w-5 text-indigo-200 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      <span className="text-sm font-medium text-white">{client.name}</span>
                    </div>
                    
                    {client.email && (
                      <div className="flex items-center bg-white/10 rounded-lg p-3 transition-all duration-200 hover:bg-white/20">
                        <svg className="h-5 w-5 text-indigo-200 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                        <a href={`mailto:${client.email}`} className="text-sm text-indigo-100 hover:text-white">
                          {client.email}
                        </a>
                      </div>
                    )}
                    
                    {client.phone && (
                      <div className="flex items-center bg-white/10 rounded-lg p-3 transition-all duration-200 hover:bg-white/20">
                        <svg className="h-5 w-5 text-indigo-200 mr-3 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <a href={`tel:${client.phone}`} className="text-sm text-indigo-100 hover:text-white">
                          {client.phone}
                        </a>
                      </div>
                    )}

                    {client.address && (
                      <div className="flex items-start bg-white/10 rounded-lg p-3">
                        <svg className="h-5 w-5 text-indigo-200 mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span className="text-sm text-indigo-100">{client.address}</span>
                      </div>
                    )}

                    <Link
                      to={`/organization/${orgId}/clients/${client.id}`}
                      className="inline-flex items-center mt-4 text-sm text-indigo-100 hover:text-white bg-white/10 rounded-lg px-4 py-2 transition-all duration-200 hover:bg-white/20 w-full justify-center"
                    >
                      <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                      Ügyfél adatlapja
                    </Link>
                  </div>
                ) : (
                  <div className="flex items-center bg-white/10 rounded-lg p-3">
                    <svg className="h-5 w-5 text-indigo-200 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span className="text-sm text-indigo-100">Nincs kiválasztva</span>
                  </div>
                )}
              </div>
            </div>

            {/* Feladatok */}
            <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-gray-900">Feladatok</h2>
                <Link
                  to={`/organization/${orgId}/projects/${projectId}/tasks/new`}
                  className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  + Új feladat
                </Link>
              </div>
              <TaskList 
                projectId={projectId}
                organizationId={orgId}
              />
            </div>
          </div>

          {/* Jobb oldali oszlop */}
          <div className="lg:w-1/3 space-y-6">
            {/* Időnyilvántartás */}
            <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-gray-900">Időnyilvántartás</h2>
                <button
                  onClick={() => setShowTimeEntry(!showTimeEntry)}
                  className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  {showTimeEntry ? 'Bezárás' : '+ Új bejegyzés'}
                </button>
              </div>

              {showTimeEntry && (
                <div className="mb-6">
                  <TimeEntryForm 
                    projectId={projectId} 
                    onSuccess={handleTimeEntrySuccess}
                  />
                </div>
              )}

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Időszak kiválasztása
                </label>
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="all">Összes bejegyzés</option>
                  {/* Utolsó 12 hónap opciói */}
                  {Array.from({ length: 12 }, (_, i) => {
                    const date = new Date();
                    date.setMonth(date.getMonth() - i);
                    const value = date.toISOString().slice(0, 7);
                    const label = date.toLocaleDateString('hu-HU', { year: 'numeric', month: 'long' });
                    return (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>

              <TimeEntriesList 
                key={timeEntriesKey}
                projectId={projectId}
                month={selectedMonth}
              />
            </div>

            {/* Dokumentumok */}
            <div className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm border border-white/20">
              <ProjectDocuments projectId={projectId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';
import UserSelect from '../common/UserSelect';
import TaskComments from './TaskComments';
import { createNotification } from '../../utils/notifications';
import { useAuth } from '../../contexts/AuthContext';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TipTapLink from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';

// A dátum megjelenítéséhez készítsünk egy segédfüggvényt
function formatDate(date) {
  if (!date) return 'Nincs megadva';
  if (date instanceof Date) return date.toLocaleDateString();
  if (date.toDate) return date.toDate().toLocaleDateString();
  return 'Érvénytelen dátum';
}

const TASK_STATUSES = {
  new: {
    label: 'Új',
    class: 'bg-blue-200 text-blue-900',
    hoverClass: 'hover:bg-blue-300'
  },
  in_progress: {
    label: 'Folyamatban',
    class: 'bg-yellow-100 text-yellow-800',
    hoverClass: 'hover:bg-yellow-200'
  },
  completed: {
    label: 'Kész',
    class: 'bg-green-100 text-green-800',
    hoverClass: 'hover:bg-green-200'
  }
};

// Add this helper function at the top of the file
const getDueDateStatus = (dueDate) => {
  if (!dueDate) return null;
  
  const today = new Date();
  const due = dueDate instanceof Date ? dueDate : dueDate.toDate();
  const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

  if (daysUntilDue < 0) return { label: 'Lejárt', class: 'text-red-700 bg-red-100', icon: '⚠️' };
  if (daysUntilDue <= 3) return { label: 'Hamarosan', class: 'text-yellow-700 bg-yellow-100', icon: '⏰' };
  return { label: 'Határidős', class: 'text-green-700 bg-green-100', icon: '📅' };
};

// Add this component for the rich text editor
function RichTextEditor({ content, onChange, placeholder = 'Add meg a feladat részletes leírását...' }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      TipTapLink.configure({
        openOnClick: false,
      }),
      Image,
      Underline,
    ],
    content: content,
    editable: true,
    autofocus: false,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      if (html !== content) {
        onChange(html);
      }
    },
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const addLink = () => {
    const url = window.prompt('Add meg a link URL-jét:');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  const addImage = () => {
    const url = window.prompt('Add meg a kép URL-jét:');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="border border-gray-300 rounded-md">
      <div className="border-b border-gray-300 bg-gray-50 p-2 flex flex-wrap gap-1">
        {/* Szöveg formázás */}
        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bold') ? 'bg-gray-200' : ''}`}
            title="Félkövér"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 4h8a4 4 0 014 4 4 4 0 01-4 4H6z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h9a4 4 0 014 4 4 4 0 01-4 4H6z" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('italic') ? 'bg-gray-200' : ''}`}
            title="Dőlt"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 4h-9M14 20H5M15 4L9 20" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('underline') ? 'bg-gray-200' : ''}`}
            title="Aláhúzott"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 3v7a6 6 0 006 6 6 6 0 006-6V3M4 21h16" />
            </svg>
          </button>
        </div>

        {/* Listák */}
        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bulletList') ? 'bg-gray-200' : ''}`}
            title="Felsorolás"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('orderedList') ? 'bg-gray-200' : ''}`}
            title="Számozott lista"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20h14M7 12h14M7 4h14M3 20h.01M3 12h.01M3 4h.01" />
            </svg>
          </button>
        </div>

        {/* Link és kép */}
        <div className="flex space-x-1">
          <button
            onClick={addLink}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('link') ? 'bg-gray-200' : ''}`}
            title="Link beszúrása"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
          </button>
          <button
            onClick={addImage}
            className="p-1.5 rounded hover:bg-gray-200"
            title="Kép beszúrása"
            type="button"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>
        </div>
      </div>
      <EditorContent 
        editor={editor} 
        className="prose max-w-none p-4 min-h-[150px] focus:outline-none"
      />
    </div>
  );
}

export default function TaskDetail() {
  const { currentUser } = useAuth();
  const [task, setTask] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedStatus, setEditedStatus] = useState('');
  const [editedDueDate, setEditedDueDate] = useState('');
  const [editedAssignedUsers, setEditedAssignedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { orgId, projectId, taskId } = useParams();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    async function fetchTask() {
      try {
        const taskRef = doc(db, 'tasks', taskId);
        const taskSnap = await getDoc(taskRef);
        
        if (!taskSnap.exists()) {
          setError('A task nem található');
          return;
        }

        const taskData = { id: taskSnap.id, ...taskSnap.data() };
        setTask(taskData);
        setEditedTitle(taskData.title);
        setEditedDescription(taskData.description || '');
        setEditedStatus(taskData.status);
        setEditedDueDate(taskData.dueDate ? taskData.dueDate.toDate().toISOString().split('T')[0] : '');
        setEditedAssignedUsers(taskData.assignedUsers || []);
      } catch (err) {
        console.error('Hiba a task betöltésekor:', err);
        setError('Hiba történt a task betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchTask();
  }, [taskId]);

  useEffect(() => {
    async function fetchProjectAndOrganization() {
      try {
        // Projekt adatok lekérése
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        if (projectSnap.exists()) {
          setProjectName(projectSnap.data().name);
          
          // Szervezet adatok lekérése
          const orgRef = doc(db, 'organizations', orgId);
          const orgSnap = await getDoc(orgRef);
          if (orgSnap.exists()) {
            setOrganizationName(orgSnap.data().name);
          }
        }
      } catch (error) {
        console.error('Hiba az adatok betöltésekor:', error);
      }
    }

    fetchProjectAndOrganization();
  }, [projectId, orgId]);

  const handleUpdate = async () => {
    try {
      const taskRef = doc(db, 'tasks', taskId);
      const updateData = {
        title: editedTitle,
        description: editedDescription,
        status: editedStatus,
        dueDate: editedDueDate ? new Date(editedDueDate) : null,
        assignedUsers: editedAssignedUsers,
        updatedAt: new Date()
      };

      await updateDoc(taskRef, updateData);

      // Frissítjük a lokális állapotot
      setTask(prev => ({
        ...prev,
        ...updateData
      }));

      // Értesítések küldése az új felelősöknek
      const newAssignees = editedAssignedUsers.filter(
        userId => !task.assignedUsers.includes(userId)
      );

      const notificationPromises = newAssignees.map(userId => 
        createNotification({
          userId,
          title: 'Feladat módosítva',
          message: `Hozzá lettél rendelve a "${editedTitle}" feladathoz`,
          type: 'task_updated',
          link: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`,
          metadata: {
            taskId,
            projectId,
            orgId
          }
        })
      );

      await Promise.all(notificationPromises);

      // Kilépünk a szerkesztő módból
      setIsEditing(false);
    } catch (error) {
      console.error('Hiba a task frissítésekor:', error);
    }
  };

  async function handleDelete() {
    if (window.confirm('Biztosan törölni szeretnéd ezt a taskot?')) {
      try {
        // Értesítés küldése minden hozzárendelt felhasználónak a törlés előtt
        const notificationPromises = task.assignedUsers
          .filter(userId => userId !== currentUser.uid)
          .map(userId => 
            createNotification({
              userId,
              title: 'Feladat törölve',
              message: `A "${task.title}" feladat törölve lett`,
              type: 'task_deleted',
              link: `/organization/${orgId}/projects/${projectId}`,
              metadata: {
                taskId,
                projectId,
                orgId
              }
            })
          );

        await Promise.all(notificationPromises);
        await deleteDoc(doc(db, 'tasks', taskId));
        navigate(`/organization/${orgId}/projects/${projectId}`);
      } catch (error) {
        console.error('Hiba a task törlésekor:', error);
      }
    }
  }

  const handleStatusChange = async (newStatus) => {
    try {
      const taskRef = doc(db, 'tasks', taskId);
      await updateDoc(taskRef, {
        status: newStatus,
        updatedAt: new Date()
      });

      // Értesítés küldése státusz változásról (magunkat is beleértve)
      const notificationPromises = task.assignedUsers
        // .filter(userId => userId !== currentUser.uid) // Ezt ideiglenesen kikommentezzük
        .map(userId => 
          createNotification({
            userId,
            title: 'Feladat státusza módosult',
            message: `A "${task.title}" feladat státusza megváltozott: ${TASK_STATUSES[newStatus].label}`,
            type: 'task_status_changed',
            link: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`,
            metadata: {
              taskId,
              projectId,
              orgId,
              newStatus
            }
          })
        );

      await Promise.all(notificationPromises);

      setTask(prev => ({
        ...prev,
        status: newStatus,
        updatedAt: new Date()
      }));
    } catch (error) {
      console.error('Hiba a státusz frissítésekor:', error);
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;
  if (!task) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </Link>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
            <span className="hidden sm:inline">{organizationName || 'Betöltés...'}</span>
            <span className="sm:hidden">{truncateName(organizationName)}</span>
          </Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}/projects/${projectId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
            <span className="hidden sm:inline">{projectName || 'Betöltés...'}</span>
            <span className="sm:hidden">{truncateName(projectName)}</span>
          </Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li className="text-gray-500 font-medium">
            <span className="hidden sm:inline">{task?.title || 'Task részletek'}</span>
            <span className="sm:hidden">{truncateName(task?.title) || 'Task részletek'}</span>
          </li>
        </ol>
      </nav>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6">
          {isEditing ? (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Task címe
                </label>
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  📝 Leírás
                </label>
                <div className="mt-1">
                  <RichTextEditor
                    content={editedDescription}
                    onChange={setEditedDescription}
                    placeholder="Add meg a feladat részletes leírását..."
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Státusz
                </label>
                <select
                  value={editedStatus}
                  onChange={(e) => setEditedStatus(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="new">Új</option>
                  <option value="in_progress">Folyamatban</option>
                  <option value="completed">Kész</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Határidő
                </label>
                <input
                  type="date"
                  value={editedDueDate}
                  onChange={(e) => setEditedDueDate(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Felelősök
                </label>
                <div className="mt-1">
                  <UserSelect
                    organizationId={orgId}
                    selectedUsers={editedAssignedUsers}
                    onChange={setEditedAssignedUsers}
                  />
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  Mégse
                </button>
                <button
                  type="button"
                  onClick={handleUpdate}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Mentés
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
                <div className="space-y-2">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                    <span className="font-mono text-xs bg-gray-100 px-2 py-0.5 rounded text-gray-600 self-start">
                      {task.code || task.id?.substring(0, 8)}
                    </span>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2 sm:mt-0">
                      {task.title}
                    </h3>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700">📝 Leírás:</h4>
                    <div 
                      className="prose max-w-none mt-2 text-sm text-gray-500"
                      dangerouslySetInnerHTML={{ __html: task.description || 'Nincs leírás' }}
                    />
                  </div>
                </div>
                
                <div className="flex flex-row sm:flex-col gap-2 justify-start">
                  <button
                    onClick={() => setIsEditing(true)}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <svg className="h-4 w-4 sm:mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    <span className="hidden sm:inline">Szerkesztés</span>
                  </button>
                  <button
                    onClick={handleDelete}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <svg className="h-4 w-4 sm:mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    <span className="hidden sm:inline">Törlés</span>
                  </button>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex flex-wrap gap-2">
                  {Object.entries(TASK_STATUSES).map(([status, info]) => (
                    <button
                      key={status}
                      onClick={() => handleStatusChange(status)}
                      disabled={task.status === status}
                      className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium transition-colors duration-150 
                        ${task.status === status 
                          ? info.class
                          : `bg-gray-50 text-gray-600 ${info.hoverClass}`
                        } ${task.status === status ? 'cursor-default' : 'cursor-pointer'}`}
                    >
                      {info.label}
                    </button>
                  ))}
                </div>
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700">Határidő:</h4>
                  {task.dueDate ? (
                    <div className="mt-1 flex items-center space-x-2">
                      {(() => {
                        const status = getDueDateStatus(task.dueDate);
                        return (
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${status.class}`}>
                            {status.icon} 
                            <span className="ml-1">{formatDate(task.dueDate)}</span>
                            {status.label !== 'Határidős' && (
                              <span className="ml-2">({status.label})</span>
                            )}
                          </span>
                        );
                      })()}
                    </div>
                  ) : (
                    <span className="text-sm text-gray-500">Nincs megadva</span>
                  )}
                </div>

                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700">Felelősök:</h4>
                  <AssignedUsersList userIds={task.assignedUsers || []} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Comments section */}
      <TaskComments 
        taskId={taskId} 
        assignedUsers={task?.assignedUsers || []}
      />
    </div>
  );
}

// Helper component to display assigned users
function AssignedUsersList({ userIds }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const userPromises = userIds.map(async (userId) => {
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            return { id: userSnap.id, ...userSnap.data() };
          }
          return null;
        });
        
        const userDetails = (await Promise.all(userPromises)).filter(user => user !== null);
        setUsers(userDetails);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    if (userIds.length > 0) {
      fetchUsers();
    }
  }, [userIds]);

  if (users.length === 0) return (
    <p className="text-sm text-gray-500 italic">Nincs hozzárendelt felhasználó</p>
  );

  return (
    <div className="mt-1 space-y-2">
      {users.map(user => (
        <div 
          key={user.id} 
          className="flex items-center space-x-3 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-150"
        >
          <div className="flex-shrink-0">
            {user.photoURL ? (
              <img 
                src={user.photoURL} 
                alt="" 
                className="h-8 w-8 rounded-full"
              />
            ) : (
              <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
                <span className="text-indigo-800 font-medium text-sm">
                  {(user.displayName || user.email || '?')[0].toUpperCase()}
                </span>
              </div>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {user.displayName || 'Névtelen'}
            </p>
            <p className="text-xs text-gray-500 truncate">
              {user.email}
            </p>
          </div>
          <div className="flex-shrink-0">
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
              👤 Felelős
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

// Add this helper function at the top of the component
const truncateName = (name, maxLength = 5) => {
  if (!name) return 'Betöltés...';
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
}; 
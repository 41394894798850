import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, getCountFromServer } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Konstansok
const TASK_STATUSES = {
  new: { label: 'Új', class: 'bg-yellow-100 text-yellow-800' },
  in_progress: { label: 'Folyamatban', class: 'bg-blue-100 text-blue-800' },
  completed: { label: 'Kész', class: 'bg-green-100 text-green-800' }
};

// Add helper function for HTML rendering
const renderHTML = (html) => {
  return { __html: html };
};

const TaskStatusBadge = ({ status }) => {
  const statusConfig = {
    new: {
      label: 'Új',
      className: 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
    },
    in_progress: {
      label: 'Folyamatban',
      className: 'bg-gradient-to-r from-yellow-400 to-yellow-500 text-white'
    },
    completed: {
      label: 'Kész',
      className: 'bg-gradient-to-r from-green-500 to-green-600 text-white'
    }
  };

  const config = statusConfig[status] || statusConfig.new;

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium shadow-sm ${config.className}`}>
      {config.label}
    </span>
  );
};

// Egyszerű prioritás badge komponens
const PriorityBadge = ({ priority }) => {
  const priorityConfig = {
    high: {
      label: 'Magas',
      className: 'bg-gradient-to-r from-red-500 to-red-600 text-white'
    },
    medium: {
      label: 'Közepes',
      className: 'bg-gradient-to-r from-orange-400 to-orange-500 text-white'
    },
    low: {
      label: 'Alacsony',
      className: 'bg-gradient-to-r from-green-500 to-green-600 text-white'
    }
  };

  const config = priorityConfig[priority] || priorityConfig.medium;

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium shadow-sm ${config.className}`}>
      {config.label}
    </span>
  );
};

// Komponens
export default function TaskList({ projectId }) {
  // State
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [spinnerChar, setSpinnerChar] = useState('⠋');
  const [filter, setFilter] = useState('all');

  // Hooks
  const navigate = useNavigate();
  const { orgId } = useParams();

  // Segédfüggvények
  const fetchTasks = async () => {
    try {
      const q = query(collection(db, 'tasks'), where('projectId', '==', projectId));
      const querySnapshot = await getDocs(q);
      
      const tasksData = await Promise.all(querySnapshot.docs.map(async doc => {
        const commentsQuery = query(collection(db, 'comments'), where('taskId', '==', doc.id));
        const commentsSnapshot = await getCountFromServer(commentsQuery);
        
        return {
          id: doc.id,
          ...doc.data(),
          commentCount: commentsSnapshot.data().count
        };
      }));
      
      setTasks(tasksData);
    } catch (error) {
      console.error('Hiba a taskok betöltésekor:', error);
      setError('Hiba történt a taskok betöltése során');
    } finally {
      setLoading(false);
    }
  };

  const handleTaskClick = (taskId) => {
    navigate(`/organization/${orgId}/projects/${projectId}/tasks/${taskId}`);
  };

  // Effektek
  useEffect(() => {
    fetchTasks();
  }, [projectId]);

  useEffect(() => {
    const spinChars = ['⠋', '⠙', '⠹', '⠸', '⠼', '⠴', '⠦', '⠧', '⠇', ''];
    const interval = setInterval(() => {
      setSpinnerChar(prev => spinChars[(spinChars.indexOf(prev) + 1) % spinChars.length]);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const filtered = tasks.filter(task => 
      task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTasks(filtered);
  }, [tasks, searchTerm]);

  // Loading és error állapotok
  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center text-gray-500">
        <span className="text-xl">{spinnerChar}</span>
        <span className="ml-2">Betöltés...</span>
      </div>
    );
  }

  if (error) {
    return <div className="p-4 text-red-600">{error}</div>;
  }

  // Komponens renderelése
  return (
    <div className="space-y-4">
      {/* Kereső */}
      <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
        <div className="relative">
          <input
            type="text"
            placeholder="Keresés a taskok között..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
      </div>

      {/* Feladatok listája */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
        <div className="divide-y divide-gray-100">
          {filteredTasks.map(task => (
            <div
              key={task.id}
              onClick={() => handleTaskClick(task.id)}
              className="group relative p-4 hover:bg-gray-50 transition-colors duration-150 cursor-pointer"
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-wrap items-center gap-2 mb-2">
                    <TaskStatusBadge status={task.status} />
                    <PriorityBadge priority={task.priority} />
                    <h3 className="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-150">
                      {task.title}
                    </h3>
                  </div>

                  {task.description && (
                    <div className="text-sm text-gray-500 line-clamp-2 mb-2"
                      dangerouslySetInnerHTML={{ __html: task.description }}
                    />
                  )}

                  <div className="flex flex-wrap items-center gap-3 text-sm">
                    {task.assignedUsers?.length > 0 && (
                      <div className="flex items-center text-gray-600">
                        <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                        {task.assignedUsers.length} hozzárendelt felhasználó
                      </div>
                    )}

                    {task.dueDate && (
                      <div className="flex items-center text-gray-600">
                        <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        {task.dueDate.toDate().toLocaleDateString()}
                      </div>
                    )}
                  </div>
                </div>

                <svg 
                  className="h-5 w-5 text-gray-400 group-hover:text-indigo-600 transition-colors duration-150" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Alkomponenesek
const TaskCards = ({ tasks, onTaskClick }) => (
  <>
    {tasks.map(task => (
      <TaskCard key={task.id} task={task} onClick={() => onTaskClick(task.id)} />
    ))}
  </>
);

const TaskCard = ({ task, onClick }) => (
  <div
    onClick={onClick}
    className="px-4 py-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
  >
    <div className="flex flex-col space-y-2">
      {/* Felső sor: kód, cím és státusz */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3 flex-grow min-w-0">
          <div className="min-w-0 flex-grow">
            <div className="flex items-center space-x-2">
              <span className="font-mono text-xs bg-gray-100 px-2 py-0.5 rounded text-gray-600">
                {task.code || task.id?.substring(0, 8)}
              </span>
              <h4 className="text-sm font-medium text-gray-900 truncate">{task.title}</h4>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 flex-shrink-0">
          <TaskStatus status={task.status} />
          {task.priority && <TaskPriority priority={task.priority} />}
        </div>
      </div>

      {/* Alsó sor: dátumok, hozzárendelt személy, kommentek */}
      <div className="flex items-center justify-between text-xs text-gray-500">
        <div className="flex items-center space-x-4">
          {/* Határidő */}
          {task.dueDate && (
            <div className="flex items-center space-x-1">
              <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <span className={`${
                new Date(task.dueDate.toDate()) < new Date() 
                  ? 'text-red-600 font-medium' 
                  : ''
              }`}>
                {task.dueDate.toDate().toLocaleDateString()}
              </span>
            </div>
          )}

          {/* Létrehozás dátuma */}
          {task.createdAt && (
            <div className="flex items-center space-x-1">
              <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>
                {task.createdAt.toDate().toLocaleDateString()}
              </span>
            </div>
          )}

          {/* Utolsó módosítás */}
          {task.updatedAt && task.updatedAt !== task.createdAt && (
            <div className="flex items-center space-x-1">
              <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              <span>
                {task.updatedAt.toDate().toLocaleDateString()}
              </span>
            </div>
          )}
        </div>

        <div className="flex items-center space-x-3">
          {/* Kommentek száma */}
          {task.commentCount > 0 && <CommentCount count={task.commentCount} />}
          
          {/* Hozzárendelt személy */}
          {task.assignedTo && (
            <div className="flex items-center space-x-1">
              <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-xs font-medium text-gray-500">
                  {task.assignedTo.name?.substring(0, 2).toUpperCase()}
                </span>
              </div>
              <span className="text-xs text-gray-500 hidden sm:inline-block">
                {task.assignedTo.name}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Leírás (ha van és nem üres) */}
      {task.description && task.description.trim() && (
        <div 
          className="text-sm text-gray-500 line-clamp-2 prose prose-sm max-w-none"
          dangerouslySetInnerHTML={renderHTML(task.description)}
        />
      )}
    </div>
  </div>
);

const TaskStatus = ({ status }) => (
  <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${TASK_STATUSES[status]?.class}`}>
    {TASK_STATUSES[status]?.label}
  </span>
);

const TaskPriority = ({ priority }) => (
  <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
    P{priority}
  </span>
);

const CommentCount = ({ count }) => (
  <span className="inline-flex items-center text-xs text-gray-500">
    <svg className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
    </svg>
    {count}
  </span>
); 
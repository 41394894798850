import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, doc, getDoc, serverTimestamp, runTransaction, query, where, getDocs } from 'firebase/firestore';
import Select from 'react-select';

export default function CreateTicket() {
  const { orgId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'normal',
    deadline: '',
    type: 'support',
    clientId: '',
    attachments: []
  });

  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const clientsQuery = query(
          collection(db, 'clients'),
          where('orgId', '==', orgId)
        );
        const clientsSnapshot = await getDocs(clientsQuery);
        const clientsData = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsData);
        
        const allContacts = clientsData.reduce((acc, client) => {
          const clientContacts = (client.contacts || []).map(contact => ({
            ...contact,
            clientId: client.id,
            clientName: client.name
          }));
          return [...acc, ...clientContacts];
        }, []);
        setContacts(allContacts);
      } catch (error) {
        setError('Hiba történt az adatok betöltésekor: ' + error.message);
      }
    }

    fetchData();
  }, [orgId]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const ticketNumber = await runTransaction(db, async (transaction) => {
        const orgRef = doc(db, 'organizations', orgId);
        const orgDoc = await transaction.get(orgRef);
        
        if (!orgDoc.exists()) {
          throw new Error('Szervezet nem található');
        }
        
        const currentNumber = orgDoc.data().lastTicketNumber || 0;
        const nextNumber = currentNumber + 1;
        
        transaction.update(orgRef, { lastTicketNumber: nextNumber });
        
        return nextNumber;
      });

      const now = new Date();
      
      const ticketData = {
        ...formData,
        deadline: formData.deadline ? new Date(formData.deadline).toISOString() : null,
        organizationId: orgId,
        ticketNumber: ticketNumber,
        status: 'new',
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        },
        contact: {
          id: formData.contactId,
          name: formData.contactName
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        assignedTo: null,
        comments: [],
        history: [{
          action: 'created',
          timestamp: now.toISOString(),
          userId: currentUser.uid,
          userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
          details: 'Ticket létrehozva'
        }]
      };

      const docRef = await addDoc(collection(db, 'tickets'), ticketData);
      navigate(`/organization/${orgId}/tickets/${docRef.id}`);
    } catch (error) {
      console.error('Ticket létrehozási hiba:', error);
      setError('Hiba történt a ticket létrehozása során: ' + error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">
          Új ticket létrehozása
        </h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Tárgy *
            </label>
            <input
              type="text"
              id="title"
              name="title"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.title}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Leírás *
            </label>
            <textarea
              id="description"
              name="description"
              rows={4}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                Prioritás
              </label>
              <select
                id="priority"
                name="priority"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.priority}
                onChange={handleChange}
              >
                <option value="low">Alacsony</option>
                <option value="normal">Normál</option>
                <option value="high">Magas</option>
                <option value="urgent">Sürgős</option>
              </select>
            </div>

            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Típus
              </label>
              <select
                id="type"
                name="type"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="support">Support</option>
                <option value="bug">Hiba</option>
                <option value="feature">Fejlesztési igény</option>
                <option value="question">Kérdés</option>
                <option value="procurement">Beszerzés</option>
                <option value="verification">Ellenőrzés</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Bejelentő *
            </label>
            <Select
              value={selectedContact}
              onChange={(selected) => {
                setSelectedContact(selected);
                setFormData(prev => ({
                  ...prev,
                  clientId: selected ? selected.clientId : '',
                  contactId: selected ? selected.value : '',
                  contactName: selected ? selected.label : ''
                }));
              }}
              options={contacts.map(contact => ({
                value: contact.email,
                label: `${contact.name} (${contact.clientName})`,
                clientId: contact.clientId,
                clientName: contact.clientName
              }))}
              className="text-sm"
              classNamePrefix="react-select"
              placeholder="Válassz kapcsolattartót..."
              noOptionsMessage={() => "Nincs találat"}
              isClearable={true}
              isSearchable={true}
              required={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#4F46E5',
                  primary75: '#6366F1',
                  primary50: '#EEF2FF',
                  primary25: '#F5F3FF',
                },
              })}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.isFocused ? '#4F46E5' : '#D1D5DB',
                  '&:hover': {
                    borderColor: '#4F46E5'
                  },
                  boxShadow: state.isFocused ? '0 0 0 1px #4F46E5' : 'none',
                  minHeight: '38px',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected 
                    ? '#4F46E5' 
                    : state.isFocused 
                      ? '#EEF2FF'
                      : 'white',
                  '&:active': {
                    backgroundColor: '#4F46E5'
                  }
                })
              }}
            />
            {formData.clientId && (
              <p className="mt-1 text-sm text-gray-500">
                Ügyfél: {clients.find(c => c.id === formData.clientId)?.name}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Csatolmányok
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Fájl feltöltése</span>
                    <input id="file-upload" name="file-upload" type="file" className="sr-only" multiple />
                  </label>
                  <p className="pl-1">vagy húzd ide</p>
                </div>
                <p className="text-xs text-gray-500">PNG, JPG, PDF max. 10MB</p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mégse
            </button>
            <button
              type="submit"
              disabled={loading || formData.clientId === ''}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400 disabled:cursor-not-allowed"
            >
              {loading ? 'Létrehozás...' : 'Ticket létrehozása'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 
import { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, orderBy, addDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import RichTextEditor from '../common/RichTextEditor';
import Avatar from '../common/Avatar';

export default function OrganizationChat() {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState('');
  const { currentUser } = useAuth();
  const notesEndRef = useRef(null);
  const { orgId } = useParams();

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Szervezet nevének betöltése
  useEffect(() => {
    const loadOrgName = async () => {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    };
    loadOrgName();
  }, [orgId]);

  // Jegyzetek real-time betöltése
  useEffect(() => {
    const q = query(
      collection(db, 'organizationNotes'),
      where('organizationId', '==', orgId),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotes(notesData);
      setLoading(false);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [orgId]);

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    try {
      const noteData = {
        content: newNote,
        organizationId: orgId,
        userId: currentUser.uid,
        createdAt: new Date(),
        createdBy: {
          id: currentUser.uid,
          name: currentUser.displayName || currentUser.email,
          photoURL: currentUser.photoURL
        }
      };

      await addDoc(collection(db, 'organizationNotes'), noteData);
      setNewNote('');
    } catch (error) {
      console.error('Hiba az üzenet küldésekor:', error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link to="/" className="text-indigo-600 hover:text-indigo-900">
              <svg className="h-3 w-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li>
            <Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900">
              {organizationName}
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">Csevegés</li>
        </ol>
      </nav>

      {/* Chat doboz */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold text-gray-900 flex items-center">
              <svg className="h-6 w-6 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
              </svg>
              Szervezeti csevegés
            </h1>
          </div>

          {/* Üzenetek listája */}
          <div className="space-y-4 h-[600px] overflow-y-auto mb-4 p-4 bg-gray-50 rounded-lg">
            {loading ? (
              <div className="text-center text-gray-500">Betöltés...</div>
            ) : notes.length === 0 ? (
              <div className="text-center text-gray-500">Még nincsenek üzenetek</div>
            ) : (
              notes.map(note => (
                <div 
                  key={note.id} 
                  className={`flex items-start space-x-3 ${
                    note.userId === currentUser.uid ? 'flex-row-reverse space-x-reverse' : ''
                  }`}
                >
                  <Avatar 
                    user={note.createdBy}
                    size="sm"
                  />
                  <div 
                    className={`flex-1 rounded-lg p-3 ${
                      note.userId === currentUser.uid 
                        ? 'bg-indigo-50 ml-12' 
                        : 'bg-white mr-12'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-sm font-medium text-gray-900">
                        {note.createdBy.name}
                      </span>
                      <span className="text-xs text-gray-500">
                        {note.createdAt.toDate().toLocaleString()}
                      </span>
                    </div>
                    <div 
                      className="prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={{ __html: note.content }}
                    />
                  </div>
                </div>
              ))
            )}
            <div ref={notesEndRef} />
          </div>

          {/* Új üzenet form */}
          <form onSubmit={handleAddNote}>
            <div className="mb-3">
              <RichTextEditor
                content={newNote}
                onChange={setNewNote}
                placeholder="Írj egy üzenetet..."
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                </svg>
                Küldés
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, collection, addDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function CreateClient() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    taxNumber: '',
    notes: '',
    hourlyRate: 0,
    travelRate: 0,
    currency: 'HUF',
    contacts: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [showContactModal, setShowContactModal] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchOrgName() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    }
    fetchOrgName();
  }, [orgId]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const clientData = {
        ...formData,
        orgId,
        hourlyRate: Number(formData.hourlyRate),
        travelRate: Number(formData.travelRate),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email
        }
      };

      const docRef = await addDoc(collection(db, 'clients'), clientData);

      navigate(`/organization/${orgId}/clients/${docRef.id}`);
    } catch (error) {
      setError('Hiba történt az ügyfél létrehozása során: ' + error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link 
              to="/" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center"
            >
              <svg 
                className="h-3 w-3 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to="/organizations" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Szervezetek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to={`/organization/${orgId}`}
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              {organizationName || 'Betöltés...'}
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to={`/organization/${orgId}/clients`}
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Ügyfelek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">
            Új ügyfél
          </li>
        </ol>
      </nav>

      <div className="max-w-2xl mx-auto">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Új ügyfél létrehozása
          </h2>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Név *
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email cím *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Telefonszám
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="taxNumber" className="block text-sm font-medium text-gray-700">
                  Adószám
                </label>
                <input
                  type="text"
                  id="taxNumber"
                  name="taxNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.taxNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Cím
              </label>
              <input
                type="text"
                id="address"
                name="address"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.address}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                Megjegyzések
              </label>
              <textarea
                id="notes"
                name="notes"
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={formData.notes}
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div>
                <label htmlFor="hourlyRate" className="block text-sm font-medium text-gray-700">
                  Óradíj
                </label>
                <input
                  type="number"
                  id="hourlyRate"
                  name="hourlyRate"
                  min="0"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.hourlyRate}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="travelRate" className="block text-sm font-medium text-gray-700">
                  Kiszállási díj
                </label>
                <input
                  type="number"
                  id="travelRate"
                  name="travelRate"
                  min="0"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.travelRate}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                  Pénznem
                </label>
                <select
                  id="currency"
                  name="currency"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.currency}
                  onChange={handleChange}
                >
                  <option value="HUF">HUF</option>
                </select>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Kapcsolattartók
                  </label>
                  <button
                    type="button"
                    onClick={() => {
                      setEditingContact(null);
                      setShowContactModal(true);
                    }}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                  >
                    <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    Új kapcsolattartó
                  </button>
                </div>

                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul className="divide-y divide-gray-200">
                    {formData.contacts.map((contact, index) => (
                      <li key={index} className="px-4 py-4">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">{contact.name}</h4>
                            <div className="mt-1 text-sm text-gray-500 space-y-1">
                              <p>{contact.position}</p>
                              <p>{contact.email}</p>
                              <p>{contact.phone}</p>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            <button
                              type="button"
                              onClick={() => {
                                setEditingContact({ ...contact, index });
                                setShowContactModal(true);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.064a2.5 2.5 0 114.95 1.007c.586-1.73 1.045-3.638 1.045-5.766 0-5.465-4.035-9.9-9.9-9.9-5.465 0-9.9 4.035-9.9 9.9 0 5.465 4.035 9.9 9.9 9.9 2.036 0 3.87-.57 5.232-1.536z" />
                              </svg>
                              Szerkesztés
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                const newContacts = [...formData.contacts];
                                newContacts.splice(index, 1);
                                setFormData({ ...formData, contacts: newContacts });
                              }}
                              className="text-red-600 hover:text-red-900"
                            >
                              Törlés
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                    {formData.contacts.length === 0 && (
                      <li className="px-4 py-4 text-sm text-gray-500 text-center">
                        Nincsenek kapcsolattartók
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Mégse
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400"
              >
                {loading ? 'Létrehozás...' : 'Ügyfél létrehozása'}
              </button>
            </div>
          </form>
        </div>
      </div>

      {showContactModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              {editingContact ? 'Kapcsolattartó szerkesztése' : 'Új kapcsolattartó'}
            </h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const contactData = {
                name: formData.get('name'),
                position: formData.get('position'),
                email: formData.get('email'),
                phone: formData.get('phone')
              };

              if (editingContact) {
                const newContacts = [...formData.contacts];
                newContacts[editingContact.index] = contactData;
                setFormData(prev => ({ ...prev, contacts: newContacts }));
              } else {
                setFormData(prev => ({
                  ...prev,
                  contacts: [...prev.contacts, contactData]
                }));
              }
              
              setShowContactModal(false);
              setEditingContact(null);
            }}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Név *</label>
                  <input
                    type="text"
                    name="name"
                    required
                    defaultValue={editingContact?.name}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Pozíció</label>
                  <input
                    type="text"
                    name="position"
                    defaultValue={editingContact?.position}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email *</label>
                  <input
                    type="email"
                    name="email"
                    required
                    defaultValue={editingContact?.email}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Telefon</label>
                  <input
                    type="tel"
                    name="phone"
                    defaultValue={editingContact?.phone}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowContactModal(false);
                    setEditingContact(null);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Mégsem
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  {editingContact ? 'Mentés' : 'Hozzáadás'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { auth } from '../../firebase/config';

export default function ProjectDocuments() {
  const [documents, setDocuments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const { projectId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  // Projekt és szervezet adatok betöltése
  useEffect(() => {
    async function fetchProjectData() {
      try {
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        if (projectSnap.exists()) {
          setOrganizationId(projectSnap.data().organizationId);
        }
      } catch (error) {
        console.error('Hiba a projekt adatok betöltésekor:', error);
      }
    }
    fetchProjectData();
  }, [projectId]);

  // Dokumentumok betöltése
  useEffect(() => {
    if (organizationId) {
      fetchDocuments();
    }
  }, [projectId, organizationId]);

  const fetchDocuments = async () => {
    try {
      const q = query(
        collection(db, 'projectDocuments'),
        where('projectId', '==', projectId)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDocuments(docs);
    } catch (error) {
      console.error('Hiba a dokumentumok betöltésekor:', error);
      setError('Hiba történt a dokumentumok betöltése során');
    }
  };

  const getStoragePath = (fileName) => {
    // Biztonságos fájlnév generálása
    const safeFileName = fileName.replace(/[^a-zA-Z0-9.-]/g, '_');
    const timestamp = Date.now();
    return `organizations/${organizationId}/projects/${projectId}/documents/${timestamp}_${safeFileName}`;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || !organizationId) {
      setError('Nincs kiválasztva fájl vagy hiányzó szervezet azonosító');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Ellenőrizzük a fájl méretét (pl. max 10MB)
      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        throw new Error('A fájl mérete nem lehet nagyobb 10MB-nál');
      }

      const storagePath = getStoragePath(file.name);
      const storageRef = ref(storage, storagePath);

      // Feltöltés a storage-ba
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Dokumentum metaadatok mentése Firestore-ba
      await addDoc(collection(db, 'projectDocuments'), {
        projectId,
        organizationId,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        downloadURL,
        storagePath,
        uploadedAt: new Date(),
        uploadedBy: auth.currentUser.uid // Hozzáadjuk a feltöltő azonosítóját
      });

      await fetchDocuments();
      
      // Sikeres feltöltés visszajelzés
      toast.success('Dokumentum sikeresen feltöltve!');
    } catch (error) {
      console.error('Hiba a fájl feltöltésekor:', error);
      setError(error.message || 'Hiba történt a fájl feltöltése során');
    } finally {
      setUploading(false);
      // Töröljük a file input értékét
      event.target.value = '';
    }
  };

  const handleDeleteDocument = async (documentId, storagePath) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a dokumentumot?')) return;

    try {
      // Használjuk a dokumentumban tárolt storage útvonalat
      const storageRef = ref(storage, storagePath);
      
      // Töröljük a fájlt a storage-ból
      await deleteObject(storageRef);
      
      // Töröljük a dokumentum referenciát a Firestore-ból
      await deleteDoc(doc(db, 'projectDocuments', documentId));
      
      // Frissítjük a listát
      await fetchDocuments();
      
      toast.success('Dokumentum sikeresen törölve!');
    } catch (error) {
      console.error('Hiba a dokumentum törlésekor:', error);
      setError('Hiba történt a dokumentum törlése során');
      toast.error('Hiba történt a dokumentum törlése során');
    }
  };

  const canPreviewFile = (fileType) => {
    return fileType.startsWith('image/') || fileType === 'application/pdf';
  };

  const handlePreview = (doc) => {
    setSelectedFile(doc);
    setIsPreviewOpen(true);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Dokumentumok
        </h3>
        
        {/* Fájl feltöltés */}
        <div className="mt-3">
          <label className="relative cursor-pointer w-full">
            <span className="inline-flex w-full justify-center items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              + Dokumentum feltöltése
            </span>
            <input
              type="file"
              className="sr-only"
              onChange={handleFileUpload}
              disabled={uploading}
            />
          </label>
          {uploading && (
            <div className="flex justify-center mt-2">
              <svg className="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>

      {/* Dokumentumok listája */}
      <div className="border-t border-gray-200">
        {documents.length === 0 ? (
          <div className="text-center py-6 text-gray-500">
            Még nincsenek feltöltött dokumentumok
          </div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {documents.map((doc) => (
              <li
                key={doc.id}
                className={`px-4 py-3 hover:bg-gray-50 ${canPreviewFile(doc.fileType) ? 'cursor-pointer' : ''}`}
              >
                <div 
                  className="flex flex-col space-y-2"
                  onClick={() => canPreviewFile(doc.fileType) && handlePreview(doc)}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                    <div className="ml-3 flex-1 truncate">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {doc.fileName}
                      </p>
                      <p className="text-xs text-gray-500">
                        {new Date(doc.uploadedAt.toDate()).toLocaleDateString()} - {(doc.fileSize / 1024 / 1024).toFixed(2)} MB
                      </p>
                    </div>
                  </div>
                  
                  <div 
                    className="flex flex-wrap gap-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {canPreviewFile(doc.fileType) && (
                      <button
                        onClick={() => handlePreview(doc)}
                        className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200"
                      >
                        👁️ Megtekintés
                      </button>
                    )}
                    <a
                      href={doc.downloadURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                    >
                      ⬇️ Letöltés
                    </a>
                    <button
                      onClick={() => handleDeleteDocument(doc.id, doc.storagePath)}
                      className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200"
                    >
                      🗑️ Törlés
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {error && (
        <div className="px-4 py-3 bg-red-50 text-red-700 text-sm">
          {error}
        </div>
      )}

      {isPreviewOpen && selectedFile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-4 w-full max-w-4xl max-h-[90vh] overflow-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">{selectedFile.fileName}</h3>
              <button
                onClick={() => setIsPreviewOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            {selectedFile.fileType.startsWith('image/') ? (
              <img
                src={selectedFile.downloadURL}
                alt={selectedFile.fileName}
                className="max-w-full h-auto"
              />
            ) : (
              <iframe
                src={selectedFile.downloadURL}
                title={selectedFile.fileName}
                className="w-full h-[80vh]"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
} 
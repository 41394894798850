import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';

// Helper function to safely format dates with time
const formatDate = (date) => {
  if (!date) return 'Nincs dátum';
  
  try {
    let dateObj;
    // Handle Firestore Timestamp
    if (date?.toDate) {
      dateObj = date.toDate();
    } else {
      // Handle regular Date object or ISO string
      dateObj = new Date(date);
    }

    return dateObj.toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Érvénytelen dátum';
  }
};

export default function SimpleTicketList({ organizationId }) {
  const [tickets, setTickets] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch clients first
        const clientsQuery = query(
          collection(db, 'clients'),
          where('orgId', '==', organizationId)
        );
        const clientsSnapshot = await getDocs(clientsQuery);
        const clientsData = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsData);

        // Then fetch tickets
        const ticketsRef = collection(db, 'tickets');
        const ticketsQuery = query(
          ticketsRef,
          where('organizationId', '==', organizationId),
          orderBy('createdAt', 'desc')
        );
        const ticketsSnapshot = await getDocs(ticketsQuery);
        
        const ticketsData = ticketsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setTickets(ticketsData);
      } catch (err) {
        console.error('Hiba az adatok betöltésekor:', err);
        setError('Hiba történt az adatok betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [organizationId]);

  const filteredTickets = tickets.filter(ticket => {
    const matchesSearch = ticket.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         ticket.id.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = !statusFilter || ticket.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;

  return (
    <div className="bg-gray-50 rounded-lg shadow-sm p-3 sm:p-6">
      <div className="flex justify-between items-center mb-4 sm:mb-6">
        <div>
          <h2 className="text-lg sm:text-2xl font-bold text-gray-900">Bejelentések</h2>
          <p className="mt-1 text-xs sm:text-sm text-gray-500">
            Aktív bejelentések listája és kezelése
          </p>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          <div className="relative w-full sm:w-64">
            <input
              type="text"
              placeholder="Keresés..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-1.5 sm:py-2 pl-8 sm:pl-10 text-xs sm:text-sm bg-white border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150"
            />
            <svg
              className="absolute left-2 sm:left-3 top-1.5 sm:top-2.5 h-4 w-4 sm:h-5 sm:w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="px-3 py-1.5 sm:py-2 text-xs sm:text-sm bg-white border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150"
          >
            <option value="">Minden státusz</option>
            <option value="new">Új</option>
            <option value="in_progress">Folyamatban</option>
            <option value="resolved">Megoldva</option>
            <option value="closed">Lezárva</option>
            <option value="waiting_for_client">Ügyfél válaszára vár</option>
            <option value="waiting_for_third_party">Harmadik fél válaszára vár</option>
            <option value="collector">Gyűjtő ticket</option>
          </select>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="divide-y divide-gray-200">
          {filteredTickets.map(ticket => (
            <div
              key={ticket.id}
              onClick={() => navigate(`/organization/${organizationId}/tickets/${ticket.id}`)}
              className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
            >
              <div className="px-3 py-3 sm:px-4 sm:py-4">
                <div className="flex items-center justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center space-x-2 sm:space-x-3">
                      <span className="text-xs sm:text-sm font-mono text-gray-500">
                        #{ticket.id.substring(0, 8)}
                      </span>
                      <h3 className="text-xs sm:text-sm font-medium text-gray-900 truncate">
                        {ticket.title}
                      </h3>
                      {ticket.clientId && (
                        <span className="text-xs text-gray-500">
                          ({clients.find(c => c.id === ticket.clientId)?.name || 'Ismeretlen ügyfél'})
                        </span>
                      )}
                    </div>
                    <div className="mt-1 flex flex-wrap items-center gap-2 sm:gap-3">
                      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-[10px] sm:text-xs font-medium
                        ${ticket.status === 'new' ? 'bg-blue-100 text-blue-800' :
                          ticket.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' :
                          ticket.status === 'waiting_for_client' ? 'bg-purple-100 text-purple-800' :
                          ticket.status === 'waiting_for_third_party' ? 'bg-orange-100 text-orange-800' :
                          ticket.status === 'collector' ? 'bg-pink-100 text-pink-800' :
                          ticket.status === 'resolved' ? 'bg-green-100 text-green-800' :
                          ticket.status === 'closed' ? 'bg-gray-100 text-gray-800' :
                          'bg-gray-100 text-gray-800'
                        }`}
                      >
                        {ticket.status === 'new' ? 'Új' :
                         ticket.status === 'in_progress' ? 'Folyamatban' :
                         ticket.status === 'resolved' ? 'Megoldva' :
                         ticket.status === 'closed' ? 'Lezárva' :
                         ticket.status === 'waiting_for_client' ? 'Ügyfél válaszára vár' :
                         ticket.status === 'waiting_for_third_party' ? 'Harmadik fél válaszára vár' :
                         ticket.status === 'collector' ? 'Gyűjtő ticket' : 'Státusz nincs beállítva'
                        }
                      </span>
                      <span className="text-[10px] sm:text-xs text-gray-500">
                        {formatDate(ticket.createdAt)}
                      </span>
                      {ticket.workTimes?.length > 0 && (
                        <span className="text-[10px] sm:text-xs text-gray-500">
                          {ticket.workTimes.reduce((acc, wt) => acc + wt.minutes, 0) / 60} óra
                        </span>
                      )}
                      {ticket.visits?.length > 0 && (
                        <span className="text-[10px] sm:text-xs text-gray-500">
                          {ticket.visits.length} kiszállás
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="ml-4">
                    <svg className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))}
          
          {filteredTickets.length === 0 && (
            <div className="text-center py-8 sm:py-12">
              <svg
                className="mx-auto h-10 w-10 sm:h-12 sm:w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">Nem található bejelentés</h3>
              <p className="mt-1 text-xs sm:text-sm text-gray-500">A megadott feltételekkel nem található bejelentés.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import Avatar from '../common/Avatar';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import RichTextEditor from '../common/RichTextEditor';
import { toast } from 'react-hot-toast';
import LoadingScreen from '../ui/LoadingScreen';
import TimeEntryForm from './TimeEntryForm';

// Segédfüggvény a dátum formázásához
const formatDate = (date) => {
  if (!date) return '';
  
  // Ha Timestamp objektum, akkor használjuk a toDate() függvényt
  if (date.toDate) {
    return date.toDate().toLocaleDateString();
  }
  
  // Ha már Date objektum vagy string, akkor konvertáljuk Date objektummá
  return new Date(date).toLocaleDateString();
};

// Segédfüggvény a dátum ISO string formátumához
const getISODate = (date) => {
  if (!date) return '';
  
  // Ha Timestamp objektum
  if (date.toDate) {
    return date.toDate().toISOString().split('T')[0];
  }
  
  // Ha már Date objektum vagy string
  return new Date(date).toISOString().split('T')[0];
};

// HTML tisztító segédfüggvény
const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

// Monogram generáló segédfüggvény
const getInitials = (name) => {
  if (!name) return '?';
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

export default function TimeEntriesList({ projectId, month, onUpdate }) {
  const { orgId } = useParams();
  const { currentUser } = useAuth();
  const [timeEntries, setTimeEntries] = useState([]);
  const [users, setUsers] = useState({});  // Felhasználók cache
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [editingEntry, setEditingEntry] = useState(null);
  const [editedDuration, setEditedDuration] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [updating, setUpdating] = useState(false);

  // Időbejegyzések újratöltése
  const refreshTimeEntries = async () => {
    try {
      setLoading(true);
      let q;

      if (month === 'all') {
        q = query(
          collection(db, 'timeEntries'),
          where('projectId', '==', projectId),
          orderBy('date', 'desc')
        );
      } else {
        const startDate = new Date(month + '-01');
        const endDate = new Date(new Date(startDate).setMonth(startDate.getMonth() + 1));
        
        q = query(
          collection(db, 'timeEntries'),
          where('projectId', '==', projectId),
          where('date', '>=', startDate),
          where('date', '<', endDate),
          orderBy('date', 'desc')
        );
      }

      const querySnapshot = await getDocs(q);
      const entries = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setTimeEntries(entries);
      const total = entries.reduce((sum, entry) => sum + entry.duration, 0);
      setTotalHours(total);
    } catch (err) {
      console.error('Hiba az időbejegyzések lekérésekor:', err);
      setError('Hiba történt az időbejegyzések betöltése során');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId && month) {
      refreshTimeEntries();
    }
  }, [projectId, month]);

  // Felhasználók betöltése - javított verzió
  useEffect(() => {
    const fetchUsers = async () => {
      const userCache = {};
      const uniqueUserIds = [...new Set(timeEntries.map(entry => entry.userId))];
      
      for (const userId of uniqueUserIds) {
        if (userId && !userCache[userId]) {
          try {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              userCache[userId] = {
                id: userDoc.id,
                ...userDoc.data()
              };
            }
          } catch (error) {
            console.error(`Hiba a felhasználó (${userId}) betöltésekor:`, error);
          }
        }
      }
      setUsers(userCache);
    };

    if (timeEntries.length > 0) {
      fetchUsers();
    }
  }, [timeEntries]);

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setEditedDuration(entry.duration.toString());
    setEditedDescription(entry.description);
    setEditedDate(getISODate(entry.date));
  };

  const handleUpdate = async () => {
    setUpdating(true);
    try {
      const entryRef = doc(db, 'timeEntries', editingEntry.id);
      const updates = {
        duration: parseFloat(editedDuration),
        description: editedDescription,
        date: new Date(editedDate),
        updatedAt: new Date(),
      };

      await updateDoc(entryRef, updates);
      
      // Frissítjük a lokális state-et
      setTimeEntries(timeEntries.map(entry => 
        entry.id === editingEntry.id 
          ? { ...entry, ...updates }
          : entry
      ));

      // Újraszámoljuk az össz óraszámot
      const total = timeEntries.reduce((sum, entry) => 
        entry.id === editingEntry.id 
          ? sum - entry.duration + parseFloat(editedDuration)
          : sum + entry.duration, 
        0
      );
      setTotalHours(total);

      setEditingEntry(null);
      toast.success('Időbejegyzés sikeresen módosítva!');
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Hiba a módosítás során:', error);
      toast.error('Hiba történt a módosítás során!');
    } finally {
      setUpdating(false);
    }
  };

  const handleDelete = async (entryId) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt az időbejegyzést?')) return;

    try {
      await deleteDoc(doc(db, 'timeEntries', entryId));
      
      // Frissítjük a lokális state-et
      const deletedEntry = timeEntries.find(e => e.id === entryId);
      setTimeEntries(timeEntries.filter(e => e.id !== entryId));
      setTotalHours(prev => prev - deletedEntry.duration);
      
      toast.success('Időbejegyzés sikeresen törölve!');
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Hiba a törlés során:', error);
      toast.error('Hiba történt a törlés során!');
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600">{error}</div>;

  // Csoportosítás hónapok szerint, ha "Összes" van kiválasztva
  const groupedEntries = month === 'all' 
    ? timeEntries.reduce((groups, entry) => {
        const monthKey = entry.date.toDate().toISOString().slice(0, 7);
        if (!groups[monthKey]) {
          groups[monthKey] = [];
        }
        groups[monthKey].push(entry);
        return groups;
      }, {})
    : { [month]: timeEntries };

  return (
    <div className="space-y-4">
      {/* Összesítés */}
      <div className="bg-indigo-50 rounded-lg p-4">
        <div className="text-sm text-indigo-600 font-medium">
          Összes idő{month === 'all' ? '' : ' ebben a hónapban'}: {totalHours.toFixed(1)} óra
        </div>
      </div>

      {/* Bejegyzések listája */}
      {timeEntries.length > 0 ? (
        <div className="space-y-6">
          {Object.entries(groupedEntries).map(([monthKey, monthEntries]) => (
            <div key={monthKey} className="space-y-2">
              {month === 'all' && (
                <h3 className="text-sm font-medium text-gray-500 px-1">
                  {new Date(monthKey).toLocaleDateString('hu-HU', { year: 'numeric', month: 'long' })}
                </h3>
              )}
              {monthEntries.map(entry => (
                <div 
                  key={entry.id}
                  className="bg-white rounded-lg border border-gray-200 p-4 hover:border-indigo-500 transition-colors duration-200"
                >
                  {editingEntry?.id === entry.id ? (
                    // Szerkesztő űrlap közvetlenül a listában
                    <div className="py-2">
                      <TimeEntryForm
                        projectId={projectId}
                        initialData={{
                          date: entry.date,
                          duration: entry.duration,
                          description: entry.description,
                          taskId: entry.taskId || '',
                          taskTitle: entry.taskTitle
                        }}
                        onSuccess={() => {
                          setEditingEntry(null);
                          refreshTimeEntries();
                          if (onUpdate) onUpdate();
                        }}
                        onCancel={() => setEditingEntry(null)}
                        isEditing={true}
                        entryId={entry.id}
                      />
                    </div>
                  ) : (
                    // Normál megjelenítés
                    <div className="relative flex gap-4">
                      {/* Bal oldali profilkép */}
                      <div className="flex-shrink-0">
                        {entry.userId && users[entry.userId] && (
                          users[entry.userId].photoURL ? (
                            <img
                              src={users[entry.userId].photoURL}
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                          ) : (
                            <div className="w-8 h-8 rounded-full bg-indigo-100 flex items-center justify-center text-sm font-medium text-indigo-600">
                              {getInitials(users[entry.userId].displayName || users[entry.userId].name || users[entry.userId].email)}
                            </div>
                          )
                        )}
                      </div>

                      {/* Középső tartalom */}
                      <div className="flex-1">
                        <div className="flex items-center gap-3">
                          {/* Név és dátum */}
                          <span className="text-sm font-medium text-gray-900">
                            {users[entry.userId]?.displayName || users[entry.userId]?.name || 'Felhasználó'}
                          </span>
                          <span className="text-sm text-gray-500">
                            • {entry.date.toDate().toLocaleDateString()}
                          </span>
                          <span className="text-sm font-medium text-gray-900 ml-auto">
                            {entry.duration} óra
                          </span>
                        </div>
                        
                        {/* Task megjelenítése, ha van */}
                        {entry.taskId && entry.taskTitle && (
                          <div className="mt-1 flex items-center gap-2">
                            <span className="inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-indigo-50 text-indigo-700">
                              <svg className="w-3 h-3 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                              </svg>
                              {entry.taskTitle}
                              {entry.taskStatus === 'completed' && ' (Lezárt)'}
                            </span>
                          </div>
                        )}
                        
                        {/* Leírás */}
                        <div className="mt-1 text-sm text-gray-600 mb-8">
                          {stripHtml(entry.description || '')}
                        </div>

                        {/* Műveleti gombok - új pozíció */}
                        {(currentUser.uid === entry.userId || currentUser.role === 'admin') && (
                          <div className="absolute bottom-0 right-0 flex gap-2">
                            <button
                              onClick={() => handleEdit(entry)}
                              className="p-1.5 rounded-lg text-gray-500 hover:text-indigo-600 hover:bg-indigo-50 transition-colors duration-200"
                              title="Módosítás"
                            >
                              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </button>
                            <button
                              onClick={() => handleDelete(entry.id)}
                              className="p-1.5 rounded-lg text-gray-500 hover:text-red-600 hover:bg-red-50 transition-colors duration-200"
                              title="Törlés"
                            >
                              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-6 bg-gray-50 rounded-lg text-gray-500">
          Nincsenek időbejegyzések {month === 'all' ? '' : 'ebben a hónapban'}
        </div>
      )}
    </div>
  );
} 
import { useState, useEffect, useRef } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, orderBy, addDoc, doc, getDoc, getDocs, Timestamp, deleteDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Avatar from '../common/Avatar';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../../firebase/config';
import { createNotification } from '../../utils/notifications';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';

// Add this component for the rich text editor
function RichTextEditor({ content, onChange, placeholder = 'Írj egy jegyzetet...', resetKey = 0 }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Link.configure({
        openOnClick: false,
      }),
      Image,
      Underline,
    ],
    content: content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  }, [resetKey]);

  const addLink = () => {
    const url = window.prompt('Add meg a link URL-jét:');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  const addImage = () => {
    const url = window.prompt('Add meg a kép URL-jét:');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="border border-gray-300 rounded-md">
      <div className="border-b border-gray-300 bg-gray-50 p-2 flex flex-wrap gap-1">
        {/* Szöveg formázás */}
        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bold') ? 'bg-gray-200' : ''}`}
            title="Félkövér"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 4h8a4 4 0 014 4 4 4 0 01-4 4H6z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 12h9a4 4 0 014 4 4 4 0 01-4 4H6z" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('italic') ? 'bg-gray-200' : ''}`}
            title="Dőlt"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 4h-9M14 20H5M15 4L9 20" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('underline') ? 'bg-gray-200' : ''}`}
            title="Aláhúzott"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 3v7a6 6 0 006 6 6 6 0 006-6V3M4 21h16" />
            </svg>
          </button>
        </div>

        {/* Listák */}
        <div className="flex space-x-1 border-r pr-2 mr-2">
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('bulletList') ? 'bg-gray-200' : ''}`}
            title="Felsorolás"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('orderedList') ? 'bg-gray-200' : ''}`}
            title="Számozott lista"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20h14M7 12h14M7 4h14M3 20h.01M3 12h.01M3 4h.01" />
            </svg>
          </button>
        </div>

        {/* Link és kép */}
        <div className="flex space-x-1">
          <button
            onClick={addLink}
            className={`p-1.5 rounded hover:bg-gray-200 ${editor.isActive('link') ? 'bg-gray-200' : ''}`}
            title="Link beszúrása"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
          </button>
          <button
            onClick={addImage}
            className="p-1.5 rounded hover:bg-gray-200"
            title="Kép beszúrása"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </button>
        </div>
      </div>
      <EditorContent 
        editor={editor} 
        className="prose max-w-none p-4 min-h-[150px] focus:outline-none"
      />
    </div>
  );
}

export default function OrganizationNotes() {
  const [notes, setNotes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({
    id: 'uncategorized',
    name: 'Téma nélküli jegyzetek',
    system: true
  });
  const [newNote, setNewNote] = useState('');
  const [newTopic, setNewTopic] = useState('');
  const [showNewTopicForm, setShowNewTopicForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState('');
  const { currentUser } = useAuth();
  const { orgId } = useParams();
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showProjectSelector, setShowProjectSelector] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [activeProjectFilter, setActiveProjectFilter] = useState(null);
  const [editingTopic, setEditingTopic] = useState(null);
  const [topicCounts, setTopicCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editorResetKey, setEditorResetKey] = useState(0);

  // Szervezet nevének betöltése
  useEffect(() => {
    const loadOrgName = async () => {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    };
    loadOrgName();
  }, [orgId]);

  // Témák betöltése
  useEffect(() => {
    const loadTopics = async () => {
      const q = query(
        collection(db, 'noteTopics'),
        where('organizationId', '==', orgId),
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(q);
      const topicsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Adjuk hozzá a "Téma nélküli" kategóriát
      setTopics([
        {
          id: 'uncategorized',
          name: 'Téma nélküli jegyzetek',
          organizationId: orgId,
          system: true
        },
        ...topicsData
      ]);
    };
    
    loadTopics();
  }, [orgId]);

  // Módosított jegyzetek betöltése szűréssel
  useEffect(() => {
    const loadNotes = async () => {
      if (!selectedTopic) return;

      let q = query(
        collection(db, 'notes'),
        where('topicId', '==', selectedTopic.id),
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(q);
      let notesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Szűrés projekt alapján
      if (activeProjectFilter) {
        notesData = notesData.filter(note => 
          note.projects?.some(p => p.id === activeProjectFilter)
        );
      }

      // Szűrés keresési kifejezés alapján
      if (searchTerm.trim()) {
        const searchLower = searchTerm.toLowerCase();
        notesData = notesData.filter(note => 
          note.content.toLowerCase().includes(searchLower)
        );
      }

      setNotes(notesData);
      setLoading(false);
    };

    if (selectedTopic) {
      loadNotes();
    }
  }, [selectedTopic, activeProjectFilter, searchTerm]);

  // Projektek betöltése
  useEffect(() => {
    const loadProjects = async () => {
      const q = query(
        collection(db, 'projects'),
        where('organizationId', '==', orgId)
      );
      
      const snapshot = await getDocs(q);
      const projectsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(projectsData);
    };
    
    loadProjects();
  }, [orgId]);

  // Jegyzetek számának lekérdezése témánként
  useEffect(() => {
    const loadTopicCounts = async () => {
      const notesQuery = query(
        collection(db, 'notes'),
        where('organizationId', '==', orgId)
      );
      
      const snapshot = await getDocs(notesQuery);
      const counts = {};
      
      snapshot.docs.forEach(doc => {
        const topicId = doc.data().topicId || 'uncategorized';
        counts[topicId] = (counts[topicId] || 0) + 1;
      });
      
      setTopicCounts(counts);
    };

    loadTopicCounts();
  }, [orgId]);

  // Új téma létrehozása
  const handleCreateTopic = async (e) => {
    e.preventDefault();
    if (!newTopic.trim()) return;

    try {
      const topicData = {
        name: newTopic,
        organizationId: orgId,
        createdAt: new Date(),
        createdBy: {
          id: currentUser.uid,
          name: currentUser.displayName || currentUser.email
        }
      };

      const docRef = await addDoc(collection(db, 'noteTopics'), topicData);
      setTopics(prev => [{
        id: docRef.id,
        ...topicData
      }, ...prev]);
      
      setNewTopic('');
      setShowNewTopicForm(false);
    } catch (error) {
      console.error('Hiba a téma létrehozásakor:', error);
    }
  };

  // Fájl feltöltés kezelése
  const handleFileUpload = async (files, noteId = null) => {
    if (!files.length) return [];
    setUploading(true);
    
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = crypto.randomUUID();
          const fileRef = ref(storage, `notes/${orgId}/${fileId}_${file.name}`);
          
          await uploadBytes(fileRef, file);
          const downloadUrl = await getDownloadURL(fileRef);
          
          return {
            id: fileId,
            name: file.name,
            url: downloadUrl,
            path: fileRef.fullPath,
            type: file.type,
            size: file.size,
            uploadedAt: Timestamp.now()
          };
        })
      );

      setUploading(false);
      return uploadedFiles;
    } catch (error) {
      console.error('Hiba a fájl feltöltésekor:', error);
      setUploading(false);
      return [];
    }
  };

  // Fájl törlése
  const handleDeleteFile = async (file, noteId) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a fájlt?')) return;

    try {
      // Töröljük a fájlt a storage-ból
      const fileRef = ref(storage, file.path);
      await deleteObject(fileRef);

      // Frissítjük a jegyzetet
      const noteRef = doc(db, 'notes', noteId);
      await updateDoc(noteRef, {
        files: arrayRemove(file)
      });

      // Frissítjük a UI-t
      setNotes(prev => prev.map(note => 
        note.id === noteId 
          ? { ...note, files: note.files.filter(f => f.id !== file.id) }
          : note
      ));
    } catch (error) {
      console.error('Hiba a fájl törlésekor:', error);
    }
  };

  // Értesítés küldése a szervezet tagjainak
  const notifyOrganizationMembers = async (type, message) => {
    try {
      const membersQuery = query(
        collection(db, 'organizationMembers'),
        where('organizationId', '==', orgId),
        where('status', '==', 'active') // Csak az aktív tagoknak
      );
      const membersSnapshot = await getDocs(membersQuery);
      
      const notificationPromises = membersSnapshot.docs
        .map(doc => doc.data().userId) // Használjuk a userId mezőt a dokumentumból
        .filter(userId => userId !== currentUser.uid) // Ne küldjünk magunknak
        .map(userId => 
          createNotification({
            userId,
            title: type === 'create' ? '📝 Új jegyzet' :
                  type === 'update' ? '📝 Jegyzet módosítva' :
                  '🗑️ Jegyzet törölve',
            message,
            type: `note_${type}d`,
            link: `/organization/${orgId}/notes`,
            metadata: {
              orgId,
              topicId: selectedTopic.id
            }
          })
        );

      await Promise.all(notificationPromises);
    } catch (error) {
      console.error('Hiba az értesítések küldésekor:', error);
    }
  };

  // Új jegyzet hozzáadása
  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim() || !selectedTopic) return;

    try {
      // Fájlok feltöltése
      const files = await handleFileUpload(fileInputRef.current.files);
      
      const noteData = {
        content: newNote,
        topicId: selectedTopic.id,
        organizationId: orgId,
        createdAt: Timestamp.now(),
        createdBy: {
          id: currentUser.uid,
          name: currentUser.displayName || currentUser.email,
          photoURL: currentUser.photoURL
        },
        projects: selectedProjects,
        files: files // Új mező a fájloknak
      };

      const docRef = await addDoc(collection(db, 'notes'), noteData);
      setNotes(prev => [{
        id: docRef.id,
        ...noteData
      }, ...prev]);
      
      setTopicCounts(prev => ({
        ...prev,
        [selectedTopic.id]: (prev[selectedTopic.id] || 0) + 1
      }));

      setNewNote('');
      setSelectedProjects([]);
      setShowProjectSelector(false);
      setEditorResetKey(prev => prev + 1);

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Értesítés küldése a szervezet tagjainak
      await notifyOrganizationMembers('create', 
        `${currentUser.displayName || currentUser.email} új jegyzetet hozott létre a "${selectedTopic.name}" témában`
      );
    } catch (error) {
      console.error('Hiba a jegyzet mentésekor:', error);
    }
  };

  // Projekt kiválasztó komponens
  const ProjectSelector = () => (
    <div className="mt-2 mb-4">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-700">Kapcsolódó projektek:</span>
        <button
          type="button"
          onClick={() => setShowProjectSelector(!showProjectSelector)}
          className="text-sm text-indigo-600 hover:text-indigo-900"
        >
          {showProjectSelector ? 'Bezárás' : 'Projektek hozzáadása'}
        </button>
      </div>
      
      {showProjectSelector && (
        <div className="bg-gray-50 p-3 rounded-md">
          <div className="flex flex-wrap gap-2">
            {projects.map(project => (
              <button
                key={project.id}
                onClick={() => {
                  setSelectedProjects(prev => {
                    if (prev.some(p => p.id === project.id)) {
                      return prev.filter(p => p.id !== project.id);
                    }
                    return [...prev, { 
                      id: project.id, 
                      name: project.name
                    }];
                  });
                }}
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                  selectedProjects.some(p => p.id === project.id)
                    ? 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {project.name}
                {selectedProjects.some(p => p.id === project.id) && (
                  <svg className="ml-1.5 h-4 w-4 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </button>
            ))}
          </div>
        </div>
      )}
      
      {selectedProjects.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedProjects.map(project => (
            <span
              key={project.id}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
            >
              {project.name}
              <button
                type="button"
                onClick={() => setSelectedProjects(prev => prev.filter(p => p.id !== project.id))}
                className="ml-1 text-indigo-600 hover:text-indigo-900"
              >
                <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </span>
          ))}
        </div>
      )}
    </div>
  );

  // Módosítsuk a dátum megjelenítését a jegyzetek listázásánál
  const formatDate = (date) => {
    if (date instanceof Timestamp) {
      return date.toDate().toLocaleString();
    }
    // Ha a date már JavaScript Date objektum vagy string
    return new Date(date).toLocaleString();
  };

  // Jegyzet törlése
  const handleDeleteNote = async (noteId) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a jegyzetet?')) return;

    try {
      // Értesítés küldése a szervezet tagjainak a törlés előtt
      await notifyOrganizationMembers('delete',
        `${currentUser.displayName || currentUser.email} törölt egy jegyzetet`
      );
      await deleteDoc(doc(db, 'notes', noteId));

      setNotes(prev => prev.filter(note => note.id !== noteId));
      
      setTopicCounts(prev => ({
        ...prev,
        [selectedTopic.id]: Math.max(0, (prev[selectedTopic.id] || 0) - 1)
      }));
    } catch (error) {
      console.error('Hiba a jegyzet törlésekor:', error);
    }
  };

  // Témaváltó komponens
  const TopicSelector = ({ currentTopicId, onTopicChange }) => (
    <div className="mt-4 mb-2">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Téma
      </label>
      <select
        value={currentTopicId}
        onChange={(e) => onTopicChange(e.target.value)}
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      >
        {topics.map(topic => (
          <option key={topic.id} value={topic.id}>
            {topic.name}
          </option>
        ))}
      </select>
    </div>
  );

  // Módosítsuk a jegyzet frissítés funkciót
  const handleUpdateNote = async (e) => {
    e.preventDefault();
    if (!editingNote || !editingNote.content.trim()) return;

    try {
      // Új fájlok feltöltése, ha vannak
      const newFiles = await handleFileUpload(fileInputRef.current.files);
      
      const noteRef = doc(db, 'notes', editingNote.id);
      const updateData = {
        content: editingNote.content,
        projects: selectedProjects,
        updatedAt: Timestamp.now(),
        topicId: editingNote.topicId,
        files: [...(editingNote.files || []), ...newFiles] // Kombináljuk a régi és új fájlokat
      };

      await updateDoc(noteRef, updateData);

      // Értesítés küldése a szervezet tagjainak
      await notifyOrganizationMembers('update',
        `${currentUser.displayName || currentUser.email} módosított egy jegyzetet`
      );

      // Ha a jegyzet ugyanabban a témában maradt, frissítjük a listában
      if (editingNote.topicId === selectedTopic.id) {
        setNotes(prev => prev.map(note => 
          note.id === editingNote.id 
            ? { 
                ...note, 
                ...updateData
              }
            : note
        ));
      } else {
        // Ha másik témába került, eltávolítjuk a listából
        setNotes(prev => prev.filter(note => note.id !== editingNote.id));
      }

      setEditingNote(null);
      setSelectedProjects([]);
      setShowProjectSelector(false);
      
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Frissítjük a számlálót, ha változott a téma
      if (editingNote.topicId !== selectedTopic.id) {
        setTopicCounts(prev => ({
          ...prev,
          [selectedTopic.id]: Math.max(0, (prev[selectedTopic.id] || 0) - 1),
          [editingNote.topicId]: (prev[editingNote.topicId] || 0) + 1
        }));
      }
    } catch (error) {
      console.error('Hiba a jegyzet módosításakor:', error);
    }
  };

  // Téma törlése - módosított verzió
  const handleDeleteTopic = async (topicId) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a témát?')) return;

    try {
      // Először keressük meg az összes jegyzetet a témában
      const notesQuery = query(
        collection(db, 'notes'),
        where('topicId', '==', topicId)
      );
      const notesSnapshot = await getDocs(notesQuery);
      
      // Frissítsük a jegyzeteket "Téma nélküli" kategóriára
      const updatePromises = notesSnapshot.docs.map(doc => 
        updateDoc(doc.ref, {
          topicId: 'uncategorized',
          updatedAt: Timestamp.now()
        })
      );
      await Promise.all(updatePromises);

      // Töröljük a témát
      await deleteDoc(doc(db, 'noteTopics', topicId));
      
      // Frissítsük a UI-t
      setTopics(prev => prev.filter(topic => topic.id !== topicId));
      
      // Ha ez volt a kiválasztott téma, váltsunk a "Téma nélküli" kategóriára
      if (selectedTopic?.id === topicId) {
        setSelectedTopic({ id: 'uncategorized', name: 'Téma nélküli jegyzetek' });
        // Újratöltjük a jegyzeteket az új kategóriával
        const uncategorizedQuery = query(
          collection(db, 'notes'),
          where('topicId', '==', 'uncategorized'),
          where('organizationId', '==', orgId),
          orderBy('createdAt', 'desc')
        );
        const snapshot = await getDocs(uncategorizedQuery);
        const notesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotes(notesData);
      }

      // Frissítjük a számlálót
      setTopicCounts(prev => {
        const newCounts = { ...prev };
        // A törölt téma jegyzeteit hozzáadjuk az uncategorized kategóriához
        newCounts['uncategorized'] = (newCounts['uncategorized'] || 0) + (newCounts[topicId] || 0);
        // Töröljük a régi téma számát
        delete newCounts[topicId];
        return newCounts;
      });
    } catch (error) {
      console.error('Hiba a téma törlésekor:', error);
    }
  };

  // Téma módosítása
  const handleUpdateTopic = async (e) => {
    e.preventDefault();
    if (!editingTopic || !editingTopic.name.trim()) return;

    try {
      const topicRef = doc(db, 'noteTopics', editingTopic.id);
      await updateDoc(topicRef, {
        name: editingTopic.name,
        updatedAt: Timestamp.now()
      });

      setTopics(prev => prev.map(topic =>
        topic.id === editingTopic.id
          ? { ...topic, name: editingTopic.name, updatedAt: Timestamp.now() }
          : topic
      ));

      if (selectedTopic?.id === editingTopic.id) {
        setSelectedTopic(prev => ({ ...prev, name: editingTopic.name }));
      }

      setEditingTopic(null);
    } catch (error) {
      console.error('Hiba a téma módosításakor:', error);
    }
  };

  // Fájl komponens
  const FilePreview = ({ file, noteId, canDelete = false }) => {
    const isImage = file.type.startsWith('image/');
    
    return (
      <div className="flex items-center space-x-2 p-2 bg-gray-50 rounded-lg">
        <div className="flex-shrink-0">
          {isImage ? (
            <img src={file.url} alt={file.name} className="h-10 w-10 object-cover rounded" />
          ) : (
            <svg className="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          )}
        </div>
        <div className="flex-1 min-w-0">
          <a 
            href={file.url} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-sm font-medium text-indigo-600 hover:text-indigo-800 truncate block"
          >
            {file.name}
          </a>
          <p className="text-xs text-gray-500">
            {(file.size / 1024 / 1024).toFixed(2)} MB
          </p>
        </div>
        {canDelete && (
          <button
            onClick={() => handleDeleteFile(file, noteId)}
            className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
          >
            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        )}
      </div>
    );
  };

  // Admin jogosultság ellenőrzése
  useEffect(() => {
    const checkAdminStatus = async () => {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      setIsAdmin(userDoc.exists() && userDoc.data().role === 'admin');
    };
    checkAdminStatus();
  }, [currentUser.uid]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <RouterLink to="/" className="text-indigo-600 hover:text-indigo-900">
              <svg className="h-3 w-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </RouterLink>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li>
            <RouterLink to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900">
              {organizationName}
            </RouterLink>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">Jegyzetek</li>
        </ol>
      </nav>

      <div className="flex gap-6">
        {/* Bal oldali témák panel */}
        <div className="w-64">
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-medium text-gray-900">Témák</h2>
                <button
                  onClick={() => setShowNewTopicForm(true)}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </button>
              </div>

              {showNewTopicForm && (
                <form onSubmit={handleCreateTopic} className="mb-4">
                  <input
                    type="text"
                    value={newTopic}
                    onChange={(e) => setNewTopic(e.target.value)}
                    placeholder="Új téma neve..."
                    className="w-full px-3 py-2 border rounded-md text-sm"
                  />
                  <div className="mt-2 flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => setShowNewTopicForm(false)}
                      className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                    >
                      Mégse
                    </button>
                    <button
                      type="submit"
                      className="px-3 py-1 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                    >
                      Létrehozás
                    </button>
                  </div>
                </form>
              )}

              <div className="space-y-1">
                {topics.map(topic => (
                  <div key={topic.id} className="group relative">
                    {editingTopic?.id === topic.id ? (
                      <form onSubmit={handleUpdateTopic} className="flex items-center space-x-2 p-1">
                        <input
                          type="text"
                          value={editingTopic.name}
                          onChange={(e) => setEditingTopic(prev => ({ ...prev, name: e.target.value }))}
                          className="flex-1 px-2 py-1 text-sm border rounded"
                          autoFocus
                        />
                        <button
                          type="submit"
                          className="p-1 text-indigo-600 hover:text-indigo-800"
                        >
                          <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() => setEditingTopic(null)}
                          className="p-1 text-gray-600 hover:text-gray-800"
                        >
                          <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </form>
                    ) : (
                      <div className="flex items-center">
                        <button
                          onClick={() => {
                            setSelectedTopic(topic);
                            setActiveProjectFilter(null);
                          }}
                          className={`flex-1 text-left px-3 py-2 rounded-md text-sm ${
                            selectedTopic?.id === topic.id
                              ? 'bg-indigo-50 text-indigo-700'
                              : 'text-gray-700 hover:bg-gray-50'
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <span>{topic.name}</span>
                            <span className={`text-xs px-2 py-0.5 rounded-full ${
                              selectedTopic?.id === topic.id
                                ? 'bg-indigo-100 text-indigo-800'
                                : 'bg-gray-100 text-gray-600'
                            }`}>
                              {topicCounts[topic.id] || 0}
                            </span>
                          </div>
                        </button>
                        {/* Csak akkor jelenjenek meg a szerkesztő gombok, ha nem rendszer kategória */}
                        {!topic.system && (topic.createdBy?.id === currentUser.uid || isAdmin) && (
                          <div className="hidden group-hover:flex items-center space-x-1 px-2">
                            <button
                              onClick={() => setEditingTopic({ id: topic.id, name: topic.name })}
                              className="p-1 text-gray-400 hover:text-indigo-600 rounded-full hover:bg-indigo-50"
                            >
                              <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleDeleteTopic(topic.id)}
                              className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
                            >
                              <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Jobb oldali jegyzetek panel */}
        <div className="flex-1">
          {selectedTopic ? (
            <div className="bg-white rounded-lg shadow">
              <div className="p-6">
                <h1 className="text-2xl font-bold text-gray-900 mb-6">
                  {selectedTopic.name}
                </h1>

                {/* Keresés és projekt szűrő */}
                <div className="space-y-6 mb-6">
                  {/* Keresés */}
                  <div>
                    <div className="relative">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Keresés a jegyzetekben..."
                        className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      />
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                      </div>
                      {searchTerm && (
                        <button
                          onClick={() => setSearchTerm('')}
                          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                        >
                          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Projekt szűrő címkefelhő */}
                  {projects.length > 0 && (
                    <div>
                      <h2 className="text-sm font-medium text-gray-700 mb-2">Szűrés projekt alapján</h2>
                      <div className="flex flex-wrap gap-2">
                        {projects.map(project => (
                          <button
                            key={project.id}
                            onClick={() => setActiveProjectFilter(
                              activeProjectFilter === project.id ? null : project.id
                            )}
                            className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium transition-colors duration-200 ${
                              activeProjectFilter === project.id
                                ? 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                          >
                            {project.name}
                            {activeProjectFilter === project.id && (
                              <svg className="ml-1.5 h-3 w-3 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            )}
                          </button>
                        ))}
                        {activeProjectFilter && (
                          <button
                            onClick={() => setActiveProjectFilter(null)}
                            className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                          >
                            Szűrő törlése
                            <svg className="ml-1.5 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* Új jegyzet form */}
                <form onSubmit={handleAddNote} className="mb-8">
                  <RichTextEditor
                    content={newNote}
                    onChange={setNewNote}
                    placeholder="Írj egy új jegyzetet..."
                    resetKey={editorResetKey}
                  />
                  <ProjectSelector />
                  <div className="mt-3">
                    <input
                      type="file"
                      ref={fileInputRef}
                      multiple
                      className="block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-indigo-50 file:text-indigo-700
                        hover:file:bg-indigo-100"
                    />
                  </div>
                  {uploading && (
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">Feltöltés folyamatban...</div>
                      <div className="mt-1 h-2 bg-gray-200 rounded-full">
                        <div 
                          className="h-2 bg-indigo-600 rounded-full transition-all duration-300"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="mt-3 flex justify-end">
                    <button
                      type="submit"
                      disabled={uploading}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50"
                    >
                      <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      Jegyzet mentése
                    </button>
                  </div>
                </form>

                {/* Jegyzetek listája */}
                <div className="space-y-6">
                  {loading ? (
                    <div className="text-center text-gray-500">Betöltés...</div>
                  ) : notes.length === 0 ? (
                    <div className="text-center text-gray-500">Még nincsenek jegyzetek ebben a témában</div>
                  ) : (
                    notes.map(note => (
                      <div key={note.id} className="bg-gray-50 rounded-lg p-4">
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            <Avatar user={note.createdBy} size="sm" />
                            <span className="text-sm font-medium text-gray-900">
                              {note.createdBy.name}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-xs text-gray-500">
                              {formatDate(note.createdAt)}
                              {note.updatedAt && ' (módosítva: ' + formatDate(note.updatedAt) + ')'}
                            </span>
                            {note.createdBy.id === currentUser.uid || isAdmin ? (
                              <div className="flex items-center space-x-1">
                                <button
                                  onClick={() => {
                                    setEditingNote({ 
                                      id: note.id, 
                                      content: note.content,
                                      topicId: note.topicId || selectedTopic.id,
                                      files: note.files || []
                                    });
                                    setSelectedProjects(note.projects || []);
                                    setShowProjectSelector(true);
                                  }}
                                  className="p-1 text-gray-400 hover:text-indigo-600 rounded-full hover:bg-indigo-50"
                                >
                                  <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleDeleteNote(note.id)}
                                  className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
                                >
                                  <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {editingNote?.id === note.id ? (
                          <form onSubmit={handleUpdateNote} className="mt-2">
                            <RichTextEditor
                              content={editingNote.content}
                              onChange={(content) => setEditingNote(prev => ({ ...prev, content }))}
                              placeholder="Jegyzet szerkesztése..."
                              resetKey={editorResetKey}
                            />
                            <TopicSelector 
                              currentTopicId={editingNote.topicId}
                              onTopicChange={(topicId) => setEditingNote(prev => ({ ...prev, topicId }))}
                            />
                            <ProjectSelector />
                            
                            {/* Meglévő fájlok megjelenítése */}
                            {editingNote.files?.length > 0 && (
                              <div className="mt-3 space-y-2">
                                <div className="text-sm font-medium text-gray-700">Csatolt fájlok:</div>
                                {editingNote.files.map(file => (
                                  <FilePreview 
                                    key={file.id} 
                                    file={file} 
                                    noteId={editingNote.id}
                                    canDelete={note.createdBy.id === currentUser.uid || isAdmin}
                                  />
                                ))}
                              </div>
                            )}
                            
                            {/* Új fájl feltöltés lehetőség */}
                            <div className="mt-3">
                              <div className="text-sm font-medium text-gray-700 mb-2">Új fájlok hozzáadása:</div>
                              <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                className="block w-full text-sm text-gray-500
                                  file:mr-4 file:py-2 file:px-4
                                  file:rounded-full file:border-0
                                  file:text-sm file:font-semibold
                                  file:bg-indigo-50 file:text-indigo-700
                                  hover:file:bg-indigo-100"
                              />
                            </div>
                            
                            {uploading && (
                              <div className="mt-2">
                                <div className="text-sm text-gray-500">Feltöltés folyamatban...</div>
                                <div className="mt-1 h-2 bg-gray-200 rounded-full">
                                  <div 
                                    className="h-2 bg-indigo-600 rounded-full transition-all duration-300"
                                    style={{ width: `${uploadProgress}%` }}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="mt-3 flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={() => {
                                  setEditingNote(null);
                                  setSelectedProjects([]);
                                  setShowProjectSelector(false);
                                }}
                                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
                              >
                                Mégse
                              </button>
                              <button
                                type="submit"
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                              >
                                Mentés
                              </button>
                            </div>
                          </form>
                        ) : (
                          <>
                            <div 
                              className="prose prose-sm max-w-none mb-3"
                              dangerouslySetInnerHTML={{ __html: note.content }}
                            />
                            {note.files?.length > 0 && (
                              <div className="mt-3 space-y-2">
                                {note.files.map(file => (
                                  <FilePreview 
                                    key={file.id} 
                                    file={file} 
                                    noteId={note.id}
                                    canDelete={note.createdBy.id === currentUser.uid || isAdmin}
                                  />
                                ))}
                              </div>
                            )}
                            {note.projects && note.projects.length > 0 && (
                              <div className="flex flex-wrap gap-1 mt-2">
                                {note.projects.map(project => (
                                  <RouterLink
                                    key={project.id}
                                    to={`/organization/${orgId}/projects/${project.id}`}
                                    className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-50 text-indigo-700 hover:bg-indigo-100"
                                  >
                                    {project.name}
                                  </RouterLink>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow p-6 text-center text-gray-500">
              Válassz egy témát a jegyzetek megtekintéséhez
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const TaskStatusBadge = ({ status }) => {
  const statusConfig = {
    new: {
      label: 'Új',
      className: 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
    },
    in_progress: {
      label: 'Folyamatban',
      className: 'bg-gradient-to-r from-yellow-400 to-yellow-500 text-white'
    },
    completed: {
      label: 'Kész',
      className: 'bg-gradient-to-r from-green-500 to-green-600 text-white'
    }
  };

  const config = statusConfig[status] || statusConfig.new;

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium shadow-sm ${config.className}`}>
      {config.label}
    </span>
  );
};

export default function MyTasksList({ onStatusChange }) {
  const [myTasks, setMyTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const fetchMyTasks = async () => {
    if (!currentUser) return;
    
    try {
      const tasksQuery = query(
        collection(db, 'tasks'),
        where('assignedUsers', 'array-contains', currentUser.uid),
        where('status', '!=', 'completed'),
        orderBy('status'),
        orderBy('createdAt', 'desc')
      );

      const tasksSnapshot = await getDocs(tasksQuery);
      const tasksData = await Promise.all(tasksSnapshot.docs.map(async taskDoc => {
        const taskData = { id: taskDoc.id, ...taskDoc.data() };
        
        // Projekt adatok lekérése
        const projectRef = doc(db, 'projects', taskData.projectId);
        const projectSnap = await getDoc(projectRef);
        taskData.project = projectSnap.exists() ? projectSnap.data() : null;
        
        // Organization adatok lekérése
        if (taskData.project?.organizationId) {
          const orgRef = doc(db, 'organizations', taskData.project.organizationId);
          const orgSnap = await getDoc(orgRef);
          taskData.organization = orgSnap.exists() ? orgSnap.data() : null;
        }
        
        return taskData;
      }));

      setMyTasks(tasksData);
    } catch (error) {
      console.error('Hiba a saját taskok betöltésekor:', error);
      toast.error('Hiba történt a feladatok betöltése során');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyTasks();
  }, [currentUser]);

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      await onStatusChange(taskId, newStatus);
      // Státuszváltozás után újratöltjük a listát
      await fetchMyTasks();
    } catch (error) {
      console.error('Hiba a státusz módosításakor:', error);
      toast.error('Hiba történt a művelet során');
    }
  };

  // Feladat megnyitása
  const handleTaskClick = (task) => {
    navigate(`/organization/${task.project?.organizationId}/projects/${task.projectId}/tasks/${task.id}`);
  };

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gradient-to-r from-indigo-100 to-indigo-50 rounded-lg w-1/3"></div>
        <div className="space-y-3">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-gradient-to-r from-gray-100 to-gray-50 h-24 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  if (myTasks.length === 0) {
    return (
      <div className="bg-gradient-to-br from-white to-indigo-50 shadow-lg rounded-lg p-6 text-center">
        <div className="text-5xl mb-4">🎉</div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Nincs aktív feladatod
        </h3>
        <p className="text-gray-500">
          Minden feladatodat elvégezted, gratulálunk!
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-100">
      <div className="bg-gradient-to-r from-indigo-600 to-blue-600 px-6 py-4">
        <h3 className="text-lg font-semibold text-white">
          Saját feladataim
        </h3>
        <p className="text-indigo-100 text-sm">
          A neked kiosztott aktív feladatok
        </p>
      </div>
      
      <div className="divide-y divide-gray-100">
        {myTasks.map(task => (
          <div 
            key={task.id} 
            className="group relative p-4 hover:bg-gray-50 transition-colors duration-150 ease-in-out cursor-pointer"
            onClick={() => handleTaskClick(task)}
          >
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div className="flex-1 min-w-0 mb-2 sm:mb-0">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <TaskStatusBadge status={task.status} />
                  <span className="text-sm font-medium text-gray-900 hover:text-indigo-600 truncate transition-colors duration-150">
                    {task.title}
                  </span>
                </div>
                
                <div className="mt-2 flex flex-wrap items-center gap-3 text-sm">
                  <div className="flex items-center text-gray-600">
                    <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                    <span className="text-indigo-600 font-medium">
                      {task.organization?.name || 'Ismeretlen szervezet'}
                    </span>
                    <span className="mx-2 text-gray-400">/</span>
                    <span>{task.project?.name || 'Ismeretlen projekt'}</span>
                  </div>
                  
                  {task.dueDate && (
                    <div className="flex items-center text-gray-600">
                      <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      {task.dueDate.toDate().toLocaleDateString()}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="flex items-center gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatusChange(task.id, task.status === 'new' ? 'in_progress' : 'completed');
                  }}
                  className={`
                    inline-flex items-center px-4 py-2 rounded-full text-sm font-medium shadow-sm
                    transition-all duration-150 ease-in-out
                    ${task.status === 'new' 
                      ? 'bg-gradient-to-r from-indigo-500 to-indigo-600 hover:from-indigo-600 hover:to-indigo-700 text-white'
                      : 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white'}
                  `}
                >
                  {task.status === 'new' ? (
                    <>
                      <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Kezdés
                    </>
                  ) : (
                    <>
                      <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Befejezés
                    </>
                  )}
                </button>

                {/* Nyíl ikon */}
                <svg 
                  className="h-5 w-5 text-gray-400 group-hover:text-indigo-600 transition-colors duration-150" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 
import React from 'react';
import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/auth/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Snowfall from './components/layout/Snowfall';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Reports from './components/reports/Reports';
import CreateOrganization from './components/organization/CreateOrganization';
import CreateTicket from './components/tickets/CreateTicket';
import TicketList from './components/tickets/TicketList';
import CreateClient from './components/clients/CreateClient';
import ClientList from './components/clients/ClientList';
import ClientDetail from './components/clients/ClientDetail';
import OrganizationSettings from './components/organization/OrganizationSettings';
import UserProfile from './components/profile/UserProfile';
import TicketView from './components/tickets/TicketView';
import SystemAdminDashboard from './components/admin/SystemAdminDashboard';
import { initializeSystemSettings } from './firebase/collections/systemSettings';
import { RequireAuth } from './components/auth/RequireAuth';
import OrganizationDetail from './components/organization/OrganizationDetail';
import NewProject from './components/project/NewProject';
import ProjectDetail from './components/project/ProjectDetail';
import NewTask from './components/task/NewTask';
import TaskDetail from './components/task/TaskDetail';
import Projects from './components/pages/Projects';
import ProjectSettings from './components/project/ProjectSettings';
import TaskListPage from './components/task/TaskListPage';
import Organizations from './components/pages/Organizations';
import TicketsPage from './components/pages/TicketsPage';
import OrganizationChat from './components/pages/OrganizationChat';
import OrganizationNotes from './components/pages/OrganizationNotes';

function App() {
  useEffect(() => {
    initializeSystemSettings().catch(console.error);
  }, []);

  return (
    <AuthProvider>
      <div className="relative">
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          } />
          <Route path="/organization/:orgId" element={
            <PrivateRoute>
              <OrganizationDetail />
            </PrivateRoute>
          } />
          <Route path="/organization/:orgId/tickets" element={
            <PrivateRoute>
              <TicketsPage />
            </PrivateRoute>
          } />
          <Route path="/organization/:orgId/reports" element={<Reports />} />
          <Route
            path="/organization/new"
            element={
              <PrivateRoute>
                <CreateOrganization />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/tickets/new"
            element={
              <PrivateRoute>
                <CreateTicket />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients/new"
            element={
              <PrivateRoute>
                <CreateClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients"
            element={
              <PrivateRoute>
                <ClientList />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients/:clientId"
            element={
              <PrivateRoute>
                <ClientDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/settings"
            element={
              <PrivateRoute>
                <OrganizationSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/tickets/:ticketId"
            element={
              <PrivateRoute>
                <TicketView />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/system"
            element={
              <PrivateRoute>
                <SystemAdminDashboard />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route 
            path="/admin/dashboard" 
            element={
              <RequireAuth>
                <SystemAdminDashboard />
              </RequireAuth>
            } 
          />
          <Route path="/organization/:orgId/projects/new" element={
            <PrivateRoute>
              <NewProject />
            </PrivateRoute>
          } />
          <Route path="/organization/:orgId/projects/:projectId" element={
            <PrivateRoute>
              <ProjectDetail />
            </PrivateRoute>
          } />
          <Route path="/organization/:orgId/projects/:projectId/tasks/new" element={
            <PrivateRoute>
              <NewTask />
            </PrivateRoute>
          } />
          <Route path="/organization/:orgId/projects/:projectId/tasks/:taskId" element={
            <PrivateRoute>
              <TaskDetail />
            </PrivateRoute>
          } />
          <Route 
            path="/organization/:orgId/projects" 
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/organization/:orgId/projects/:projectId/settings" 
            element={
              <PrivateRoute>
                <ProjectSettings />
              </PrivateRoute>
            } 
          />
          <Route path="/organization/:orgId/projects/:projectId/tasks" element={<TaskListPage />} />
          <Route path="/organizations" element={<Organizations />} />
          <Route 
            path="/organization/:orgId/chat" 
            element={
              <RequireAuth>
                <OrganizationChat />
              </RequireAuth>
            } 
          />
          <Route 
            path="/organization/:orgId/notes" 
            element={
              <RequireAuth>
                <OrganizationNotes />
              </RequireAuth>
            } 
          />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
